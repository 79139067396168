import React, { Component } from "react";
import CustomNavbar from "../navigation/customNavbar";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  logoutTeacher,
  inactivateJob,
  reactivateJob,
  removeApplicant,
} from "../../actions/authActions";
import { Container, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import "../../styles/appliedTempJobs.css";
import Spinner from "../func-components/spinner";
import Sort from "../func-components/sort";
import {
  faCheckCircle,
  faSpinner,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { io } from "socket.io-client";
import axios from "axios";
import LongCard from "../cards/longCard";
import BlockCard from "../cards/blockCard";
import { default as CheckSolid } from "../users-only/icons/check-solid.svg";
import { default as Teachers } from "../navigation/icons/calendar-check-regular.svg";
import MobileLayout from "../mobile/mobileLayout";
import fi from "../../texts/fi.json";
import sv from "../../texts/sv.json";

class AppliedTempJobs extends Component {
  _isMounted = false;
  constructor() {
    super();
    this.state = {
      tempJobs: [],
      inactiveJobs: [],
      isLoading: true,
      errors: {},
      jobSpec: {},
      sidebar: window.matchMedia("(max-width: 1450px)").matches ? false : true,
      listSort: true,
      isOpen: false,
      mobile: false,
      language: {},
    };
  }

  componentDidMount() {
    this._isMounted = true;

    if (this.props.auth.teacher.typeOfUser !== "user") {
      this.props.history.push("/jobs/overview");
    }
    const handler = () => {
      let match = window.matchMedia("(max-width: 600px)").matches;
      if (match) {
        this._isMounted && this.setState({ mobile: true });
      } else {
        this._isMounted && this.setState({ mobile: false });
      }
    };
    handler();
    window.addEventListener("resize", handler);

    const data = {
      email: this.props.auth.teacher.email,
    };
    this._isMounted && this.getLang(this.props.auth.teacher);
    axios
      .post("/api/users/getpersonaltemp", data)
      .then((res) => {
        let jobs = this.sortArray(res.data.activejobs);
        let inactivejobs = res.data.inactivejob || [];
        this.setState({
          tempJobs: jobs,
          inactivejobs: inactivejobs,
          isLoading: false,
        });
      })
      .catch((err) => console.log(err));

    const socket = io();
    socket.on("chosen", () => {
      axios
        .post("/api/users/getpersonaltemp", data)
        .then((res) => {
          this._isMounted &&
            this.setState({
              tempJobs: this.sortArray(res.data.activejobs),
              inactivejobs: res.data.inactivejobs,
            });
        })
        .catch((err) => console.log(err));
    });
    socket.on("updateapplied", () => {
      axios
        .post("/api/users/getpersonaltemp", data)
        .then((res) => {
          this._isMounted &&
            this.setState({
              tempJobs: this.sortArray(res.data.activejobs),
              inactivejobs: res.data.inactivejobs,
            });
        })
        .catch((err) => console.log(err));
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  getLang = (user) => {
    if (user.siteLang === 2) {
      this.setState({ language: sv });
    } else if (user.siteLang === 1) {
      this.setState({ language: fi });
    } else {
      this.setState({ language: fi });
    }
  };

  handleOpenChange = () => {
    this.setState({ isOpen: true });
  };
  handleCloseChange = () => {
    this.setState({ isOpen: false });
  };

  sortArray = (arr) => {
    arr.sort((a, b) => {
      if (a.chosen === this.props.auth.teacher.email) {
        return -1;
      }
      if (typeof a.chosen === "undefined") {
        return 1;
      }
      return 0;
    });
    arr.forEach((item) => {
      if (
        item.chosen !== this.props.auth.teacher.email &&
        typeof item.chosen !== "undefined"
      ) {
        arr.push(arr.splice(arr.indexOf(item), 1)[0]);
      }
    });
    return arr;
  };

  showSidebar = () => {
    const { sidebar } = this.state;
    if (sidebar) {
      this.setState({ sidebar: false });
    } else if (!sidebar) {
      this.setState({ sidebar: true });
    }
  };

  displayDates = (job) => {
    var options = { day: "numeric", month: "numeric" };
    if (
      new Date(job.startDate).toDateString() ===
      new Date(job.endDate).toDateString()
    ) {
      return new Date(job.startDate).toLocaleDateString("fi-FI");
    }
    return (
      new Date(job.startDate).toLocaleDateString("fi-FI", options) +
      "-" +
      new Date(job.endDate).toLocaleDateString("fi-FI")
    );
  };

  onLogoutClick = () => {
    this.props.logoutTeacher();
  };

  statusContainer = (job) => {
    //NOTE: SELF. styles are defined in userDashStyle
    if (job.chosen === this.props.auth.teacher.email) {
      return (
        <Container className="status-container accepted">
          <OverlayTrigger
            placement="auto"
            delay={{ show: 250, hide: 400 }}
            overlay={this.acceptedTooltip}
          >
            <FontAwesomeIcon className="icon-lg" icon={faCheckCircle} />
          </OverlayTrigger>
        </Container>
      );
    } else if (typeof job.chosen === "undefined") {
      return (
        <Container className="status-container pending">
          <OverlayTrigger
            placement="auto"
            delay={{ show: 250, hide: 400 }}
            overlay={this.pendingTooltip}
          >
            <FontAwesomeIcon className="icon-lg" icon={faSpinner} />
          </OverlayTrigger>
        </Container>
      );
    } else if (job.chosen !== this.props.auth.teacher.email) {
      return (
        <Container className="status-container denied">
          <OverlayTrigger
            placement="auto"
            delay={{ show: 250, hide: 400 }}
            overlay={this.deniedTooltip}
          >
            <FontAwesomeIcon className="icon-lg" icon={faTimesCircle} />
          </OverlayTrigger>
        </Container>
      );
    }
  };

  smallCardContainer = (job) => {
    if (job.chosen === this.props.auth.teacher.email) {
      return (
        <Container
          className={
            this.state.listSort
              ? "mobile-container accepted"
              : "mobile-container accepted a-border"
          }
        >
          <OverlayTrigger
            placement="auto"
            delay={{ show: 250, hide: 400 }}
            overlay={this.acceptedTooltip}
          >
            <FontAwesomeIcon className="icon-lg" icon={faCheckCircle} />
          </OverlayTrigger>
        </Container>
      );
    } else if (typeof job.chosen === "undefined") {
      return (
        <Container
          className={
            this.state.listSort
              ? "mobile-container pending"
              : "mobile-container pending p-border"
          }
        >
          <OverlayTrigger
            placement="auto"
            delay={{ show: 250, hide: 400 }}
            overlay={this.pendingTooltip}
          >
            <FontAwesomeIcon className="icon-lg" icon={faSpinner} />
          </OverlayTrigger>
        </Container>
      );
    } else if (job.chosen !== this.props.auth.teacher.email) {
      return (
        <Container
          className={
            this.state.listSort
              ? "mobile-container denied"
              : "mobile-container denied d-border"
          }
        >
          <OverlayTrigger
            placement="auto"
            delay={{ show: 250, hide: 400 }}
            overlay={this.deniedTooltip}
          >
            <FontAwesomeIcon className="icon-lg" icon={faTimesCircle} />
          </OverlayTrigger>
        </Container>
      );
    }
  };

  openJob = (e) => {
    this.props.history.push(`/specific/${e.currentTarget.id}`);
  };

  listOrBlock = (e) => {
    if (e.currentTarget.id === "block") {
      this.setState({ listSort: false });
    }
    if (e.currentTarget.id === "list") {
      this.setState({ listSort: true });
    }
  };

  checkIfChosen = (job) => {
    const { teacher } = this.props.auth;
    if (job.chosen === teacher.email && typeof job.chosen !== "undefined") {
      return "chosen";
    } else if (
      typeof job.chosen !== "undefined" &&
      job.chosen !== teacher.email
    ) {
      return "not-chosen";
    } else {
      return "reg";
    }
  };

  checkStatus = (job) => {
    const { teacher } = this.props.auth;
    if (job.chosen === teacher.email && typeof job.chosen != "undefined") {
      return (
        <div className="mobile-chosen-container">
          <img alt="" src={CheckSolid} className="mobile-check" />
        </div>
      );
    } else if (
      typeof job.chosen != "undefined" &&
      job.chosen != teacher.email
    ) {
      return <div className="mobile-not-chosen-container"></div>;
    } else {
      return (
        <div className="mobile-chosen-container">
          <img alt="" src={CheckSolid} className="mobile-check" />
        </div>
      );
    }
  };

  handleSort = (e) => {
    switch (e.target.id) {
      case "datum":
        var sorted = this.state.tempJobs.sort((a, b) =>
          a.startDate.localeCompare(b.startDate)
        );
        this.setState({ tempJobs: sorted });
        break;
      case "tid":
        var sorted = this.state.tempJobs.sort((a, b) =>
          a.startTime.localeCompare(b.startTime)
        );
        this.setState({ tempJobs: sorted });
        break;
      default:
        break;
    }
  };

  acceptedTooltip = (props) => {
    return (
      <Tooltip id="accepted-tooltip" {...props}>
        Indikerar att du har blivit vald
      </Tooltip>
    );
  };
  pendingTooltip = (props) => {
    return (
      <Tooltip id="pending-tooltip" {...props}>
        Ingen vikarie har blivit vald ännu
      </Tooltip>
    );
  };
  deniedTooltip = (props) => {
    return (
      <Tooltip id="denied-tooltip" {...props}>
        Någon annan har blivit vald som vikarie
      </Tooltip>
    );
  };

  render() {
    const {
      tempJobs,
      isLoading,
      sidebar,
      listSort,
      mobile,
      inactivejobs,
      language,
    } = this.state;
    const { teacher } = this.props.auth;
    const imgUrl = `${window.location.origin}/api/shared/image/`;
    return (
      <div className="temp-body">
        <CustomNavbar
          userType={teacher.typeOfUser}
          sidebar={sidebar}
          showSidebar={this.showSidebar}
          page={language.nav_applied}
          user={teacher}
          logoutTeacher={this.onLogoutClick}
          isAuth={this.props.auth.isAuthenticated}
        />
        {!mobile && (
          <>
            <Sort
              header=""
              listOrBlock={this.listOrBlock}
              handleSort={this.handleSort}
              userType={teacher.typeOfUser}
              marginLeft={sidebar ? "235px" : ""}
            />
            <hr
              style={{ marginTop: "60px", position: "fixed", zIndex: "9" }}
              className="custom-hr"
            ></hr>
          </>
        )}
        {/* {mobile && <MobileHeader text="Anmälningar" icon={Vikariat} />} */}
        <div className={sidebar ? "main push-right" : "main "}>
          <Container
            fluid
            style={{ paddingLeft: "0", paddingRight: "0", minHeight: "100%" }}
          >
            {!isLoading ? (
              !mobile ? (
                listSort ? (
                  tempJobs.map((job, index) => (
                    <LongCard
                      bell={this.checkStatus(job)}
                      job={job}
                      index={index}
                      openJob={this.openJob}
                      dates={this.displayDates(job)}
                      chosen={this.checkIfChosen(job)}
                      language={language}
                    />
                  ))
                ) : (
                  <Row>
                    {tempJobs.map((job, index) => (
                      <BlockCard
                        bell={this.checkStatus(job)}
                        job={job}
                        index={index}
                        openJob={this.openJob}
                        dates={this.displayDates(job)}
                        chosen={this.checkIfChosen(job)}
                        language={language}
                      />
                    ))}
                  </Row>
                )
              ) : (
                <MobileLayout
                  icon={Teachers}
                  text={language.nav_applied}
                  jobs={tempJobs}
                  inactivejobs={inactivejobs}
                  mobileBell={this.checkStatus}
                  displayDates={this.displayDates}
                  openJob={this.openJob}
                  renderCard={true}
                  user={teacher}
                  chosen={this.checkIfChosen}
                  swipeable={true}
                  inactivatejob={this.props.inactivateJob}
                  activatejob={this.props.reactivateJob}
                  removeJob={this.props.removeApplicant}
                  language={language}
                />
              )
            ) : (
              <div className="spin-wrapper">
                <Spinner size="8x" spinning={"spinning"} />
              </div>
            )}
          </Container>
        </div>
      </div>
    );
  }
}
AppliedTempJobs.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  logoutTeacher: PropTypes.func.isRequired,
  removeApplicant: PropTypes.func.isRequired,
  inactivateJob: PropTypes.func.isRequired,
  reactivateJob: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  logoutTeacher,
  inactivateJob,
  reactivateJob,
  removeApplicant,
})(withRouter(AppliedTempJobs));
