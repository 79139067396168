import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

const initialState = {};

const middleware = [thunk];

const store = createStore(
  rootReducer,
  initialState,
  /* combineReducers({
        ...rootReducer,
        routing: routerReducer
    }), */
  compose(applyMiddleware(...middleware))
);

export default store;
