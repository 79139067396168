let data = [
    "Svenska",
    "Suomi",
    "English",
    "Dansk",
    "Norsk",
    "Deutsch",
    "La France",
    "Pусский"
]
export default data;
