import React from "react";
import { Dropdown } from "react-bootstrap";
/**
 * @typedef {("FI" | "SE")} Language
 * */
/**
 * @typedef {("FI" | "SE")} Language
 * */
/**
 * @typedef {("FI" | "SE")} Language
 * */
/**
 * @param {boolean} multiple - Define if more than one "school".
 * @param {{codes: string[], names: string[]}} codeInfo - Array of code - name objects.
 * @param {Language} language - Users chosen application language
 */
const SchoolCodePage = ({ multiple = false, codeInfo, language }) => {
  return (
    <div style={{ paddingLeft: "15px" }} className="mt-3">
      {multiple ? (
        <Dropdown>
          <Dropdown.Toggle className="custom-dropdown" id="dropdown-basic">
            {language === "FI" ? "Yksikkön koodit" : "Enheternas koder"}
          </Dropdown.Toggle>
          <Dropdown.Menu className="sort-dropdown">
            {codeInfo?.codes?.map((code, idx) => {
              return (
                <Dropdown.Item disabled className="dd-item" id={code}>
                  {codeInfo?.names[idx]}: <b>{code}</b>
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <div style={{ fontSize: "1.6em" }}>
          {language === "FI" ? "Koulun kood" : "Skolans kod"}:{" "}
          <b>{codeInfo?.codes?.map((code) => code)}</b>
        </div>
      )}
    </div>
  );
};
export default SchoolCodePage;
