import React from 'react'
import { Container, Card, Row, Col, Form, Button } from 'react-bootstrap';
import GradeArray from '../../typeaheadData/gradeArray';
import { Typeahead } from 'react-bootstrap-typeahead';

const EditTeacher = (props) => {
    const { language, onSubmit, onChange, teacher, firstName, lastName, email, phone, cancel, setgrades, grades } = props;
    return (
        <Container fluid>
            <Card className="mt-3" style={{ borderRadius: "10px", border: "none", boxShadow: "0px 0px 24px 0px rgba(13, 17, 77, 0.10)" }}>
                <Form noValidate onSubmit={onSubmit}>
                    <Row>
                        <Col>
                            <Card.Body className="mt-3">
                                <Card.Title>
                                    <h4>{language.basicinfo}</h4>
                                </Card.Title>
                                <Row>
                                    <Form.Group controlId="firstName" as={Col}>
                                        <Form.Label className="text-muted lt cBold">{language.firstname}</Form.Label>
                                        <Form.Control className="custom-control" onChange={onChange} value={firstName} placeholder={teacher.firstName} type="text" />
                                    </Form.Group>
                                    <Form.Group controlId="lastName" as={Col}>
                                        <Form.Label className="text-muted lt cBold">{language.lastname}</Form.Label>
                                        <Form.Control className="custom-control" onChange={onChange} value={lastName} placeholder={teacher.lastName} type="text" />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group controlId="email" as={Col}>
                                        <Form.Label className="text-muted lt cBold">{language.email}</Form.Label>
                                        <Form.Control className="custom-control" onChange={onChange} value={email} placeholder={teacher.email} type="email" />
                                    </Form.Group>
                                    <Form.Group controlId="phone" as={Col}>
                                        <Form.Label className="text-muted lt cBold">{language.phone}</Form.Label>
                                        <Form.Control className="custom-control" onChange={onChange} value={phone} placeholder={teacher.phone} type="phone" />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group controlId="grade" as={Col}>
                                        <Form.Label className="text-muted lt cBold">{language.grade}</Form.Label>
                                        <Typeahead
                                            className="subject-picker"
                                            id="grade-picker"
                                            multiple
                                            placeholder={language.oneormore_standard}
                                            defaultSelected={teacher.grade}
                                            options={language.fi ? GradeArray.fi : GradeArray.sv}
                                            onChange={(grades) => setgrades(grades)}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button
                                            className="mt-3"
                                            type="submit"
                                            variant="saveTeacher"
                                            block
                                        >
                                            {language.saveteacher}
                                        </Button>
                                        <Button
                                            className="mt-3"
                                            onClick={cancel}
                                            variant="saveTeacher red-variant"
                                            block
                                        >
                                            {language.cancel}
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </Container>
    )
}

export default EditTeacher
