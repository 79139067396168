import React from "react";
import GDPR from "../../legal/gdpr";
import Backbar from "../../mobile/backbar";
import { Button, Row, Card, Form, Col } from "react-bootstrap";

const Security = (props) => {
  const { setrender, language, desktop } = props;
  return (
    <div>
      <Card
        className={desktop ? "mt-2" : ""}
        style={{
          marginLeft: desktop ? "" : "1em",
          marginRight: desktop ? "" : "1em",
          borderRadius: "20px",
          border: "none",
          boxShadow: "0px 0px 24px 0px rgba(13, 17, 77, 0.10)",
          height: "100%",
          marginTop: "1em",
        }}
      >
        <Backbar
          setrender={setrender}
          title={language.security}
          sticky={!desktop}
          noBack={desktop}
          desktop={desktop}
          padding={true}
        />
        <Card.Body style={{ marginTop: "0" }}>
          <Row>
            <Col>
              <GDPR language={language} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Security;
