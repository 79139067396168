import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'

const LanguagePicker = (props) => {
    const { handleLang, language } = props;
    return (
        <div style={{  }}>
            <Dropdown>
                <Dropdown.Toggle className="custom-dropdown" id="dropdown-basic">
                    {language === "fi" ? "Språk" : "Kieli"}
                </Dropdown.Toggle>
                <Dropdown.Menu className="sort-dropdown">
                    <Dropdown.Item className="dd-item" id="sv" onClick={handleLang}>Svenska</Dropdown.Item>
                    <Dropdown.Item className="dd-item" id="fi" onClick={handleLang}>Suomi</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default LanguagePicker
