import React from "react";
import { Button, Modal } from "react-bootstrap";
import { default as CloseIcon } from "../users-only/icons/close_purple.svg";
import { Link } from "react-router-dom";

const ChangeSchoolModal = (props) => {
  const {
    isConfirmOpen,
    handleCloseConfirmChange,
    prin,
    changeSchool,
    language,
  } = props;
  return (
    <Modal
      show={isConfirmOpen}
      onHide={handleCloseConfirmChange}
      dialogClassName="apply-consent-modal"
      centered
      animation={false}
    >
      <Modal.Header>
        <Modal.Title style={{ fontSize: "2em" }}>
          {language.fi ? "Valitse yksikkö" : "Välj enhet"}
        </Modal.Title>
        <button
          type="button"
          className="close"
          onClick={handleCloseConfirmChange}
          style={{ opacity: "1" }}
        >
          <span aria-hidden="true">
            <img
              src={CloseIcon}
              alt=""
              className="errorApplyCloseBtn"
              style={{ width: "1.3em" }}
            />
          </span>
          <span className="sr-only">Close</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <>
          <p style={{ fontSize: "1.6em", fontWeight: "500" }} id={prin?.school}>
            {prin?.school}{" "}
            <Link
              className="forgot-link"
              to="#"
              onClick={() => changeSchool(prin?.school)}
            >
              {language.fi ? "valitse" : "välj"}
            </Link>
          </p>{" "}
        </>
        {prin?.moreSchools?.map((school, index) => {
          return (
            <>
              <p style={{ fontSize: "1.6em", fontWeight: "500" }} id={school}>
                {school}{" "}
                <Link
                  className="forgot-link"
                  to="#"
                  onClick={() => changeSchool(school)}
                >
                  {language.fi ? "valitse" : "välj"}
                </Link>
              </p>{" "}
            </>
          );
        })}
      </Modal.Body>
      <Modal.Footer className="px-0 py-4">
        {/* <Button variant="custom" block onClick={() => console.log("ok")}>Säng</Button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeSchoolModal;
