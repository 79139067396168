import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import logo from "../../images/logo.png";
import "./legal.css";
import GdprFI from "./gdprFI";
import GdprSV from "./gdprSV";
import LanguagePicker from "../func-components/languagePicker";
import raw_sv from "./gdprSVTXT.txt";
import raw_fi from "./gdprFITXT.txt";

const GDPR = (props) => {
  const { language } = props;
  const [lang, setLang] = useState("fi");

  const handleLanguage = (e) => {
    setLang(e.currentTarget.id);
  };
  return (
    <div>
      <Container
        className="d-flex flex-column align-items-center"
        fluid
        style={{ paddingLeft: "0", paddingRight: "0", minHeight: "100%" }}
      >
        <div className="d-inline-block text-left ml-2">
          <div className="mb-3 language-picker">
            <LanguagePicker language={lang} handleLang={handleLanguage} />
          </div>
          {lang === "fi" ? <GdprFI /> : <GdprSV />}
        </div>
      </Container>
    </div>
  );
};

export default GDPR;
