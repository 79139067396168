import React, {useEffect, useState} from "react"
import isEmail from "validator/es/lib/isEmail";
import Cookies from "js-cookie";
import logo from "../../images/logo.png";
import {Button, Container, Form} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import {sendNewConfirmationEmail} from "../../actions/authActions";
import {toast, ToastContainer} from "react-toastify";

const RequestNewActivationPage = (props) => {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [error, setError] = useState(false);
    const [language, setLanguage] = useState("");

    useEffect(() => {
        setLanguage(Cookies?.getJSON('_lang') ?? "fi")
    }, [])

    const handleSendNew = async () => {
        setLoading(true);
        if(!email || email === "" || !isEmail(email)){
            setError(true)
            return;
        }
        const data = {
            email
        };
        const response = await sendNewConfirmationEmail(data)
        if(response?.message === "Already confirmed"){
            toast.info(language === "fi" ? "Olen jo aktivoinut" : "Ditt konto är redan aktiverat")
        }
        setLoading(false);
    }

    return (
        <Container className="vh-100 vw-100 d-flex flex-column align-items-center justify-content-center">
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                style={{fontSize: "1.8em", fontWeight: "600"}}
            />
            <img src={logo} alt="Logo" />
            <div className="w-100" style={{maxWidth: "35em"}}>
                <Form.Group className="mt-5">
                    <Form.Control
                        id="email"
                        type="email"
                        placeholder={language === "fi" ? "Sähköposti" : "E-post"}
                        value={email}
                        onChange={(e) => {
                            setEmail(e.currentTarget.value)
                            setError(false);
                        }}
                        autoCapitalize="none"
                        className={error ? "border-danger" : ""}
                    />
                </Form.Group>
                <Button variant="custom" block onClick={handleSendNew}>
                    {language === "fi" ? "Lähetä uusi aktivointilinkki" : "Skicka ny aktiveringslänk"}
                </Button>
            </div>
        </Container>
    )
}
export default RequestNewActivationPage;