import React from "react";
import { Modal } from "react-bootstrap";
import { default as BookMarked } from "../../images/Bookmark_purple_outline.svg";

const ViewsModal = ({ open, setOpen, job, viewUsers, rememberedUsers }) => {
  const getNameFromEmail = (email) => {
    let user = viewUsers?.find((e) => e?.email === email);
    return user ? `${user.firstName} ${user.lastName}` : "";
  };

  const userIsRemembered = (email) => {
    if (!rememberedUsers) {
      return false;
    }
    return rememberedUsers?.includes(email);
  };

  return (
    <Modal
      centered
      show={open}
      shouldCloseOnOverlayClick
      onHide={() => setOpen("")}
      style={{ fontSize: "2.2em", fontWeight: 500 }}
    >
      <Modal.Body>
        <div>
          {job?.views?.map((view) => (
            <div className="d-flex align-items-center">
              <p className="m-0 p-0">{getNameFromEmail(view)}</p>
              {userIsRemembered(view) ? (
                <img
                  className="ml-2"
                  src={BookMarked}
                  width={15}
                  alt="bookmark icon"
                />
              ) : null}
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ViewsModal;
