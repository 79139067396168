import React from "react";
import {default as Back} from "../navigation/icons/back.svg";

/* TODO: fix sticky backbar functionality */
const Backbar = (props) => {
  const { setrender, title, padding, goback, sticky, desktop, noBack } = props;
  return !noBack ? (
    <div
      onClick={
        setrender
          ? (event) => {
              setrender(false);
            }
          : () => {
              goback();
            }
      }
      style={{
        padding: padding && "0.6rem 1.25rem 0rem 1.25rem",
        cursor: "pointer",
        height: "50px",
      }}
      className={sticky ? "sticky-backbar" : ""}
    >
      <p
        style={{
          fontSize: "20px",
          fontWeight: "500",
          textAlign: desktop ? "left" : "center",
          position: "relative",
          paddingLeft: desktop ? "2em" : "",
        }}
        className="text-muted"
      >
        <img
          alt=""
          src={Back}
          style={{
            width: "12px",
            position: "absolute",
            left: "0",
            top: "50%",
            transform: "translateY(-50%)",
          }}
        />
        {title}
      </p>
      <hr
        style={{
          width: "100%",
          height: "1px",
          border: "none",
          transform: "translateY(-8px)",
        }}
      ></hr>
    </div>
  ) : (
    <div
      style={{
        padding: padding && "0.6rem 1.25rem 0rem 1.25rem",
      }}
      className={sticky ? "sticky-backbar" : ""}
    >
      <p
        style={{
          fontSize: "20px",
          fontWeight: "500",
          textAlign: "center",
          position: "relative",
          paddingLeft: "",
        }}
        className="text-muted"
      >
        {title}
      </p>
      <hr
        style={{
          width: "100%",
          height: "1px",
          border: "none",
          transform: "translateY(-8px)",
        }}
      ></hr>
    </div>
  );
};

export default Backbar;
