import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import "../../styles/principalSettings.css";
import CustomNavbar from "../navigation/customNavbar";
import { Typeahead } from "react-bootstrap-typeahead";
import Subjects from "../../typeaheadData/subjects";
import Subjects_fi from "../../typeaheadData/subjects_fi";
import {
  activateAllTeachers,
  logoutTeacher,
  saveSchoolSettings,
} from "../../actions/authActions";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import axios from "axios";
import fi from "../../texts/fi.json";
import sv from "../../texts/sv.json";
import { hours, minutes } from "../tempjob/data/times";
import { default as Editpost } from "../navigation/icons/edit_post.svg";
import Sort from "../func-components/sort";
import { withAlert } from "react-alert";
import DefaultDescriptionCard from "../cards/DefaultDescriptionCard";

class PrincipalSettings extends Component {
  constructor() {
    super();
    this.state = {
      activation: true,
      sidebar: window.matchMedia("(max-width: 1450px)").matches ? false : true,
      subjects: [],
      start: "",
      end: "",
      info: "",
      defaultDescription: "",
      prin: {},
      edit: false,
      language: {},
      render: false,
      component: null,
    };
  }

  componentDidMount() {
    if (this.props.auth.teacher.typeOfUser !== "principal") {
      this.props.history.push("/profile");
    }
    this.getLang(this.props.auth.teacher);

    let data = {
      email: this.props.auth.teacher.email,
    };
    axios
      .post("/api/principals/get/principal", data)
      .then((res) => {
        this.setState({
          prin: res.data,
          info: res?.data?.defaults?.info,
          start: res?.data?.defaults?.start,
          end: res?.data?.defaults?.end,
          subjects: res?.data?.defaults?.subjects,
        });
      })
      .catch((err) => console.log(err));
  }

  onLogoutClick = () => {
    this.props.logoutTeacher();
  };

  getLang = (user) => {
    if (user.siteLang === 2) {
      this.setState({ language: sv });
    } else if (user.siteLang === 1) {
      this.setState({ language: fi });
    } else {
      this.setState({ language: fi });
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleActivate = (e) => {
    if (e.target.value === "false") {
      this.setState({ activation: true });
    }
    if (e.target.value === "true") {
      this.setState({ activation: false });
    }
  };

  setComponent = (num) => {
    this.setState({ component: num });
  };
  setRender = (bool) => {
    this.setState({ render: bool });
  };

  onSubmit = (e) => {
    const alert = this.props.alert;
    e.preventDefault();
    const s = this.state;

    const data = {
      email: this.props.auth.teacher.email,
      activate: s.activation,
      subjects: s.subjects,
      start: s.start,
      end: s.end,
      info: s.info,
    };

    this.props.saveSchoolSettings(data);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  handleEdit = () => {
    this.setState({ edit: true });
  };

  showSidebar = () => {
    const { sidebar } = this.state;
    if (sidebar) {
      this.setState({ sidebar: false });
    } else if (!sidebar) {
      this.setState({ sidebar: true });
    }
  };

  render() {
    const {
      sidebar,
      prin,
      subjects,
      start,
      end,
      info,
      edit,
      language,
      component,
      render,
    } = this.state;
    const { teacher } = this.props.auth;
    const imgUrl = `${window.location.origin}/api/shared/image/`;
    return (
      <div className="temp-body">
        <CustomNavbar
          userType={teacher.typeOfUser}
          sidebar={sidebar}
          showSidebar={this.showSidebar}
          page={language.settings}
          user={teacher}
          logoutTeacher={this.onLogoutClick}
          isAuth={this.props.auth.isAuthenticated}
        />
        <Sort
          header={language.settings}
          userType={teacher.typeOfUser}
          marginLeft={sidebar ? "215px" : "16px"}
          school={teacher.school}
        />
        <hr
          style={{ marginTop: "60px", position: "fixed", zIndex: "9" }}
          className="custom-hr"
        ></hr>
        <div className={sidebar ? "main push-right" : "main"}>
          <Container fluid>
            <DefaultDescriptionCard user={teacher} language={language} />

            <Card
              style={{
                borderRadius: "20px",
                border: "none",
                boxShadow: "0px 0px 24px 0px rgba(13, 17, 77, 0.10)",
                marginTop: "20px",
              }}
            >
              <Form noValidate onSubmit={this.onSubmit}>
                <Row>
                  <Col>
                    <Card.Body>
                      {/* <Row className="f-col">
                                                <Form.Group as={Col}>
                                                    <Form.Label className="text-muted lt cBold">Aktivering</Form.Label>
                                                    <Form.Check
                                                        type="switch"
                                                        id="activation"
                                                        value={this.state.activation}
                                                        onChange={this.handleActivate}
                                                        label="Behöver du aktivera vikariat före dom publiceras?"
                                                    />
                                                </Form.Group>
                                            </Row> */}
                      <Row className="f-col">
                        <Col>
                          <h4 style={{ display: "flex" }}>
                            {language.sos} - {language.sos_standardtext}
                            <img
                              alt=""
                              src={Editpost}
                              className="edit-icon ml-auto"
                              onClick={this.handleEdit}
                            />
                          </h4>
                        </Col>
                      </Row>
                      <Row className="f-col">
                        <Form.Group controlId="start" as={Col}>
                          <Form.Label className="text-muted lt cBold">
                            {language.begins}
                          </Form.Label>
                          {!edit ? (
                            <Form.Control
                              disabled
                              className="custom-control"
                              placeholder={
                                prin?.defaults?.start
                                  ? prin.defaults.start
                                  : start
                              }
                            />
                          ) : (
                            <Form.Control
                              as="select"
                              onChange={this.onChange}
                              value={start}
                            >
                              {hours.map((hour, hr_index) =>
                                minutes.map((min, index) => (
                                  <option key={index}>
                                    {hour + ":" + min}
                                  </option>
                                ))
                              )}
                            </Form.Control>
                          )}
                        </Form.Group>
                        <Form.Group controlId="end" as={Col}>
                          <Form.Label className="text-muted lt cBold">
                            {language.ends}
                          </Form.Label>
                          {!edit ? (
                            <Form.Control
                              disabled
                              className="custom-control"
                              placeholder={
                                prin?.defaults?.end ? prin.defaults.end : end
                              }
                            />
                          ) : (
                            <Form.Control
                              as="select"
                              onChange={this.onChange}
                              value={end}
                            >
                              {hours.map((hour, hr_index) =>
                                minutes.map((min, index) => (
                                  <option
                                    disabled={
                                      start >= hour + ":" + min ? true : false
                                    }
                                    key={index}
                                  >
                                    {hour + ":" + min}
                                  </option>
                                ))
                              )}
                            </Form.Control>
                          )}
                        </Form.Group>
                      </Row>
                      <Row className="f-col">
                        <Form.Group controlId="subjects" as={Col}>
                          <Form.Label className="text-muted lt cBold">
                            {language.chosensubjects}
                          </Form.Label>
                          {!edit ? (
                            <Form.Control
                              disabled
                              className="custom-control"
                              placeholder={
                                prin?.defaults?.subjects
                                  ? prin.defaults.subjects.map(
                                      (subject, index) =>
                                        (index ? " " : "") + subject
                                    )
                                  : subjects.map(
                                      (subject, index) =>
                                        (index ? " " : "") + subject
                                    )
                              }
                            />
                          ) : (
                            <Typeahead
                              className="subject-picker"
                              id="subject-picker"
                              multiple
                              allowNew
                              newSelectionPrefix={
                                language.sv ? "Lägg till: " : "Lisää: "
                              }
                              options={language.sv ? Subjects : Subjects_fi}
                              placeholder={language.oneormore}
                              defaultSelected={prin?.defaults?.subjects}
                              onChange={(subjects) => {
                                subjects.forEach((subject) => {
                                  if (typeof subject === "object")
                                    subjects[subjects.indexOf(subject)] =
                                      subject?.label ?? null;
                                });
                                this.setState({ subjects });
                              }}
                            />
                          )}
                        </Form.Group>
                        <Form.Group controlId="info" as={Col}>
                          <Form.Label className="text-muted lt cBold">
                            {language.extra}
                          </Form.Label>
                          {!edit ? (
                            <Form.Control
                              disabled
                              className="custom-control"
                              as="textarea"
                              placeholder={
                                prin?.defaults?.info ? prin.defaults.info : info
                              }
                            />
                          ) : (
                            <Form.Control
                              as="textarea"
                              rows={4}
                              onChange={this.onChange}
                              value={info}
                            />
                          )}
                        </Form.Group>
                      </Row>
                      {edit && (
                        <Row>
                          <Col>
                            <Button
                              className="mt-2"
                              variant="deactive"
                              block
                              size="lg"
                              onClick={() => this.setState({ edit: false })}
                            >
                              {language.cancel}
                            </Button>
                            <Button
                              block
                              variant="custom"
                              size="lg"
                              type="submit"
                            >
                              {language.savechanges}
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </Card.Body>
                  </Col>
                </Row>
              </Form>
              {/* <Row>
                                <Col>
                                    <Card.Body>
                                        <Card.Title>{language.activateall}</Card.Title>
                                        <Row className="f-col">
                                            <Col>
                                                <Button onClick={() => this.props.activateAllTeachers({ school: prin.school })} block variant="active">{language.activate}</Button>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Col>
                            </Row> */}
            </Card>
            {/* {!render ? (
                            <Card className="mb-4 mt-2" style={{ borderRadius: "14px", border: "none", boxShadow: "0px 0px 24px 0px rgba(13, 17, 77, 0.10)", }}>
                                <Card.Body >

                                    <Card.Text onClick={() => {
                                        this.setRender(true)
                                        this.setComponent(1)
                                    }} className="list-text-dt d-flex">{language.notifications} <FontAwesomeIcon color="#707070" className="ml-auto mt-1" icon={faChevronRight} size="xs" /></Card.Text>

                                    <hr className="list-separator"></hr>

                                    <Card.Text onClick={() => {
                                        this.setRender(true)
                                        this.setComponent(2)
                                    }} className="list-text-dt d-flex">{language.account} <FontAwesomeIcon color="#707070" className="ml-auto mt-1" icon={faChevronRight} size="xs" /></Card.Text>

                                    <hr className="list-separator"></hr>

                                    <Card.Text onClick={() => {
                                        this.setRender(true)
                                        this.setComponent(4)
                                    }} className="list-text-dt d-flex">{language.security} <FontAwesomeIcon color="#707070" className="ml-auto mt-1" icon={faChevronRight} size="xs" /></Card.Text>

                                    <hr className="list-separator"></hr>

                                    <Card.Text className="list-text-dt d-flex">{language.support} <FontAwesomeIcon color="#707070" className="ml-auto mt-1" icon={faChevronRight} size="xs" /></Card.Text>

                                    <hr className="list-separator"></hr>
                                    <Button variant="custom" block size="lg" onClick={this.props.logoutTeacher}>{language.logout}</Button>
                                </Card.Body>
                            </Card>
                        )
                            :
                            (component === 1 &&
                                <Notifications setrender={this.setRender} language={language} desktop={true} />)
                            || (component === 2 &&
                                <Account setrender={this.setRender} language={language} desktop={true} />)
                            || (component === 4 && <Security setrender={this.setRender} language={language} desktop={true}/>)
                        } */}
          </Container>
        </div>
      </div>
    );
  }
}

PrincipalSettings.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  logoutTeacher: PropTypes.func.isRequired,
  saveSchoolSettings: PropTypes.func.isRequired,
  activateAllTeachers: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  logoutTeacher,
  saveSchoolSettings,
  activateAllTeachers,
})(withRouter(withAlert()(PrincipalSettings)));
