import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import CustomNavbar from "../navigation/customNavbar";
import "../../styles/vikariat.css";
import "../../styles/body-verticalCenter.css";
import { Card, Col, Container, Dropdown, Row } from "react-bootstrap";
import Sort from "../func-components/sort";
import Spinner from "../func-components/spinner";
import {
  activateJob,
  deleteJobFromDropdown,
  logoutTeacher,
} from "../../actions/authActions";
import { default as EditIcon } from "../navigation/icons/edit_post.svg";
import sv from "../../texts/sv.json";
import fi from "../../texts/fi.json";
import { withAlert } from "react-alert";
import { default as EditDots } from "../tempjob/icons/dot_menu.svg";
import { default as RemoveIcon } from "../tempjob/icons/delete_purple.svg";
import { default as CheckSolid } from "../users-only/icons/check-solid.svg";

class Vikariat extends Component {
  constructor() {
    super();
    this.state = {
      tempJobs: [],
      teachers: [],
      errors: {},
      listSort: true,
      isLoading: true,
      sidebar: window.matchMedia("(max-width: 1450px)").matches ? false : true,
      language: {},
      preschool: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let match = window.matchMedia("(max-width: 600px)").matches;
    if (match) {
      this.props.history.push("/jobs/overview");
    }
    if (this.props.auth.teacher.typeOfUser === "user") {
      this.props.history.push("/profile");
    }
    this.getLang(this.props.auth.teacher);
    if (this.props.auth.teacher.typeOfUser === "teacher") {
      this.getTeachersData().finally();
    }

    if (this.props.auth.teacher.typeOfUser === "principal") {
      this.getPrincipalData().finally();
    }
  }

  getLang = (user) => {
    if (user.siteLang === 2) {
      this.setState({ language: sv });
    } else if (user.siteLang === 1) {
      this.setState({ language: fi });
    } else {
      this.setState({ language: fi });
    }
  };

  getPrincipalData = async () => {
    const getTempInfoData = {
      owner: this.props.auth.teacher.id,
      email: this.props.auth.teacher.email,
    };
    await axios.post("/api/principals/temps", getTempInfoData).then((res) => {
      this.setState(
        { tempJobs: res.data.jobs, preschool: res.data.preschool },
        () => {
          this.state.tempJobs.sort(
            (a, b) => new Date(a.startDate) - new Date(b.startDate)
          );
        }
      );
    });
    const data2 = {
      email: this.props.auth.teacher.email,
    };
    await axios.post("/api/principals/teachers/getall", data2).then((res) => {
      this.setState({ teachers: res?.data?.teachers ?? [], isLoading: false });
    });
  };

  getTeachersData = async () => {
    const getTempInfoData = {
      owner: this.props.auth.teacher.id,
      email: this.props.auth.teacher.email,
    };
    await axios
      .post("/api/teachers/gettemp", getTempInfoData)
      .then((res) => {
        this.setState({ tempJobs: res.data.jobs, isLoading: false }, () => {
          this.state.tempJobs.sort(
            (a, b) => new Date(a.startDate) - new Date(b.startDate)
          );
        });
      })
      .catch((err) => console.log(err));
  };

  displayDates = (job) => {
    var options = { day: "numeric", month: "numeric" };
    if (
      new Date(job.startDate).toDateString() ===
      new Date(job.endDate).toDateString()
    ) {
      return new Date(job.startDate).toLocaleDateString("fi-FI");
    }
    return (
      new Date(job.startDate).toLocaleDateString("fi-FI", options) +
      "-" +
      new Date(job.endDate).toLocaleDateString("fi-FI")
    );
  };

  activate = (e) => {
    const data = {
      id: e.target.id,
    };
    this.props.history.push("/teachers");
    /* this.props.activateJob(data) */
  };

  listOrBlock = (e) => {
    if (e.currentTarget.id === "block") {
      this.setState({ listSort: false });
    }
    if (e.currentTarget.id === "list") {
      this.setState({ listSort: true });
    }
  };
  onLogoutClick = () => {
    this.props.logoutTeacher();
  };

  showSidebar = () => {
    const { sidebar } = this.state;
    if (sidebar) {
      this.setState({ sidebar: false });
    } else if (!sidebar) {
      this.setState({ sidebar: true });
    }
  };

  handleDelete = (e) => {
    const alert = this.props.alert;

    let data = {
      id: e.currentTarget.id,
    };
    this.props.deleteJobFromDropdown(data);

    /* if (window.confirm("Är du säker att du vill ta bort detta vikariatet?")) {
                                                                                this.props.deleteJobFromDropdown(data)
                                                                            } else {
                                                                                alert.info("Ingen ändring")
                                                                            } */
  };

  customToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <img alt="" className="ml-5 edit-ellipsis" src={EditDots} />
    </a>
  ));

  handleSort = (e) => {
    switch (e.target.id) {
      case "alfabetisk":
        var sorted = this.state.teachers.sort((a, b) =>
          a.lastName.localeCompare(b.lastName)
        );
        this.setState({ teachers: sorted });
        break;
      case "teacher":
        var sorted = this.state.teachers.sort((a, b) =>
          a.startTime.localeCompare(b.startTime)
        );
        this.setState({ teachers: sorted });
        break;
      case "datum":
        var sorted = this.state.tempJobs.sort((a, b) =>
          a.startDate.localeCompare(b.startDate)
        );
        this.setState({ tempJobs: sorted });
        break;
      case "reverse":
        var sorted = this.state.tempJobs.sort((a, b) =>
          b.startDate.localeCompare(a.startDate)
        );
        this.setState({ tempJobs: sorted });
        break;
      default:
        break;
    }
  };

  render() {
    const {
      listSort,
      sidebar,
      isLoading,
      tempJobs,
      teachers,
      language,
      preschool,
    } = this.state;
    const { teacher } = this.props.auth;
    const imgUrl = `${window.location.origin}/api/shared/image/`;
    return (
      <div className="temp-body">
        <CustomNavbar
          userType={teacher.typeOfUser}
          sidebar={sidebar}
          showSidebar={this.showSidebar}
          page={language.nav_tempjobs}
          user={teacher}
          logoutTeacher={this.onLogoutClick}
          isAuth={this.props.auth.isAuthenticated}
        />
        <Sort
          header={language.vikariatheader}
          listOrBlock={this.listOrBlock}
          handleSort={this.handleSort}
          userType={teacher.typeOfUser}
          marginLeft={sidebar ? "215px" : "16px"}
          school={teacher.school}
          isJobs={true}
        />
        <hr
          style={{ marginTop: "60px", position: "fixed", zIndex: "9" }}
          className="custom-hr"
        ></hr>
        <div className={sidebar ? "main push-right" : "main"}>
          <Container fluid style={{ marginTop: "1rem" }}>
            {!isLoading ? (
              listSort ? (
                <Card className="vikariat-card-list">
                  <Card.Body>
                    <Col>
                      <Row className="list-headers gray-line">
                        <Col>{language.nav_teachers}</Col>
                        <Col>
                          {preschool ? language.school : language.grade}
                        </Col>
                        <Col>{language.timeofleave}</Col>
                        <Col>{language.reason}</Col>
                        <Col>{language.chosenbanner}</Col>
                        <Col md="2"> </Col>
                      </Row>
                      {tempJobs?.map((job) => (
                        <React.Fragment key={job.id}>
                          <Row
                            className="gray-line v-row-hover"
                            as="a"
                            href={`/manage/${job.uniqueId}`}
                          >
                            <Col
                              /* as="a" href={`/manage/${job.uniqueId}`} */
                              className="teacher-name"
                            >
                              {job.owner !== teacher.id && teachers
                                ? teachers?.map((teach) => {
                                    if (job.owner === teach._id) {
                                      return `${teach.firstName} ${teach.lastName}`;
                                    }
                                    return "";
                                  })
                                : typeof job.regarding !== "undefined" &&
                                  job.regarding !== ""
                                ? `${job.regarding}`
                                : `${teacher.firstName} ${teacher.lastName}`}
                            </Col>
                            <Col className="text-content">
                              {preschool ? job.school : job?.grades.join(", ")}
                            </Col>
                            <Col className="text-content">
                              {this.displayDates(job)}
                            </Col>
                            <Col className="text-content">
                              {job.reason ? job.reason : "-"}
                            </Col>
                            <Col className="text-content">
                              {job?.chosen && (
                                <div className="vikariat-chosen-container">
                                  <img
                                    alt=""
                                    src={CheckSolid}
                                    className="vikariat-check"
                                  />
                                </div>
                              )}
                            </Col>
                            <Col className="text-content" md="2">
                              <Dropdown>
                                <Dropdown.Toggle
                                  as={this.customToggle}
                                  id="dd-custom-toggle"
                                ></Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    className="dd-item"
                                    eventKey="1" /* href={`/manage/${job.uniqueId}`} */
                                  >
                                    <Link
                                      to={{
                                        pathname: `/manage/${job.uniqueId}`,
                                        state: {
                                          fromEditIcon: true,
                                        },
                                      }}
                                    >
                                      <img
                                        alt=""
                                        src={EditIcon}
                                        className="edit-job-icon mr-3"
                                      />
                                      {language.editdropdwn}
                                    </Link>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="dd-item"
                                    eventKey="2"
                                    id={job.uniqueId}
                                    onClick={this.handleDelete}
                                  >
                                    <img
                                      alt=""
                                      src={RemoveIcon}
                                      className="edit-job-icon mr-3"
                                    />
                                    {language.remove}
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </Col>
                          </Row>
                        </React.Fragment>
                      ))}
                    </Col>
                  </Card.Body>
                </Card>
              ) : (
                <Row>
                  {tempJobs?.map((job) => (
                    <Col key={job.id} lg={3} md={6} sm={12} className="mb-4">
                      <Card
                        key={job.id}
                        as="a"
                        href={`/manage/${job.uniqueId}`}
                        className="vikariat-card-block"
                      >
                        <Card.Body>
                          <Card.Title className="block-title">
                            <Col>
                              {job.owner !== teacher.id && teachers
                                ? teachers?.map((teach) => {
                                    if (job.owner === teach.id) {
                                      return `${teach.firstName} ${teach.lastName}`;
                                    }
                                    return "";
                                  })
                                : typeof job.regarding !== "undefined" &&
                                  job.regarding !== ""
                                ? `${job.regarding}`
                                : `${teacher.firstName} ${teacher.lastName}`}
                            </Col>
                            <Col className="text-content mr-3" md="2">
                              <Dropdown>
                                <Dropdown.Toggle
                                  as={this.customToggle}
                                  id="dd-custom-toggle"
                                ></Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    className="dd-item"
                                    eventKey="1" /* href={`/manage/${job.uniqueId}`} */
                                  >
                                    <Link
                                      to={{
                                        pathname: `/manage/${job.uniqueId}`,
                                        state: {
                                          fromEditIcon: true,
                                        },
                                      }}
                                    >
                                      <img
                                        alt=""
                                        src={EditIcon}
                                        className="edit-job-icon mr-3"
                                      />
                                      {language.editdropdwn}
                                    </Link>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="dd-item"
                                    eventKey="2"
                                    id={job.uniqueId}
                                    onClick={this.handleDelete}
                                  >
                                    <img
                                      alt=""
                                      src={RemoveIcon}
                                      className="edit-job-icon mr-3"
                                    />
                                    {language.remove}
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </Col>
                          </Card.Title>
                          <Card.Text className="text-content">
                            <Col>
                              {language.grade + " - " + job?.grades.join(", ")}
                            </Col>
                          </Card.Text>
                          <Card.Text className="text-content">
                            <Col>{this.displayDates(job)}</Col>
                          </Card.Text>
                          <Card.Text className="text-content">
                            <Col>{job.reason ? job.reason : "-"}</Col>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              )
            ) : (
              <div className="spin-wrapper">
                <Spinner size="8x" spinning={"spinning"} />
              </div>
            )}
          </Container>
        </div>
      </div>
    );
  }
}

Vikariat.propTypes = {
  activateJob: PropTypes.func.isRequired,
  logoutTeacher: PropTypes.func.isRequired,
  deleteJobFromDropdown: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  activateJob,
  logoutTeacher,
  deleteJobFromDropdown,
})(withRouter(withAlert()(Vikariat)));
