import React, { useEffect } from 'react'
import { Col } from 'react-bootstrap'

const FAQ = ({ faq, index, toggleFaq, showQ, lang }) => {

    /* useEffect(() => {
        console.log("Loggin FAQ")
        console.log(faq)
        console.log(index)
        console.log(showQ)
    }, []) */

    return (
        <div className="question-container">
            <p onClick={() => toggleFaq(index)} className={showQ === index ? "question-header-open noselect" : "question-header noselect"}
            ><span className="question-text my-auto" style={{marginRight: ""}}>{lang.fi ? faq.question_fi : faq.question}</span></p>
            <div id={index} className={showQ === index ? "answer-container open" : "answer-container"} >
                <p className="answer-header">
                    {lang.fi? faq.answer_fi : faq.answer}
                </p>
            </div>
        </div>
    )
}

export default FAQ
