import React from "react";
import jwt_decode from "jwt-decode";
import { withRouter } from "react-router-dom";
import store from "../store";
import { setCurrentTeacher } from "../actions/authActions";
import setAuthToken from "./setAuthToken";

/* if (localStorage.jwtToken) {
  const token = localStorage.jwtToken;
  setAuthToken(token);
  const decoded = jwt_decode(token);
  store.dispatch(setCurrentTeacher(decoded));

  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutTeacher());

    window.location.href = "./login";
  }
} */

const parseJwt = (token) => {
  try {
    const decoded = jwt_decode(token);
    return decoded;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const AuthVerify = (props) => {
  props.history.listen(() => {
    const token = localStorage.jwtToken;

    if (token) {
      const decoded = parseJwt(token);
      setAuthToken(token);
      store.dispatch(setCurrentTeacher(decoded));

      if (decoded.exp * 1000 < Date.now()) {
        props.logOut();
      }
    }
  });
  return <></>;
};

export default withRouter(AuthVerify);
