let subjectData = [
    'Biologia',
    'Elämänkatsomustieto',
    'Englanti',
    'Fysiikka',
    'Historia',
    'Kemia',
    'Kotitalous',
    'Kuvaamotaito',
    'Käsityö',
    'Liikunta',
    'Maantieto',
    'Matematiikka',
    'Musiikki',
    'Ruotsi',
    'Terveystieto',
    'Uskonto',
    'Yhteiskuntaoppi',
    'Ympäristöoppi',
    'Äidinkieli ja kirjallisuus'
]
export default subjectData;

/* 
'Matematiikka', 
    'Ruotsi', 
    'Biologia', 
    'Voimistelu', 
    'Musiikki', 
    'Historia', 
    'Fysiikka', 
    'Maantiede', 
    'Kotimaan talous',
    'Uskonto', 
    'Puukäsityö', 
    'Suomalainen', 
    'Käsityö', 
    'Englanti', 
    'Yhteiskuntaoppi', 
    'Kemia', 
    'Kuvataide',
    'Elämänkatsomustieto' */