/* let data = [
    "Förskola", "1", "2", "3", "4", "5", "6", "7", "8", "9"
] */
let data = {
  fi: [
    "Päiväkoti",
    "Esikoulu",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "Kansanopisto",
  ],
  sv: [
    "Daghem",
    "Förskola",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "Folkhögskola",
  ],
};
export default data;
