import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Backbar from "../../mobile/backbar";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const Support = (props) => {
  const { setrender, mobile, language } = props;
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const sendSupportEmail = () => {
    let data = {
      subject,
      message,
      email: props.auth.teacher.email,
    };
    axios.post("/api/shared/email/support", data).then((res) => {
      if (!res.data.success) {
        toast.error(language.supportfail, {
          toastId: "supportsent",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setSubject("");
        setMessage("");
      }
      toast.success(language.supportsuccess, {
        toastId: "supportsent",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setSubject("");
      setMessage("");
    });
  };

  return (
    <div className="">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ fontSize: "1.8em", fontWeight: "600" }}
      />
      <Card
        /* className={!mobile ? "mt-2" : ""} */ style={{
          marginLeft: !mobile ? "" : "1em",
          marginRight: !mobile ? "" : "1em",
          borderRadius: "20px",
          border: "none",
          boxShadow: "0px 0px 24px 0px rgba(13, 17, 77, 0.10)",
          height: "100%",
          marginTop: "10px",
        }}
      >
        <Backbar
          setrender={setrender}
          title="Support"
          padding={true}
          desktop={!mobile}
          noBack={!mobile}
        />
        <Card.Body style={{ marginTop: "0", fontWeight: "500" }}>
          <Form>
            <Row className="f-col">
              <Col md={12} lg={6}>
                <p className="support-header">{language.supportheader}</p>
                <Form.Group controlId="subject">
                  <Form.Label className="text-muted lt cBold">
                    {language.subject}
                  </Form.Label>
                  <Form.Control
                    onChange={(e) => setSubject(e.target.value)}
                    className="custom-control"
                    value={subject}
                  />
                </Form.Group>
                <Form.Group controlId="message">
                  <Form.Label className="text-muted lt cBold">
                    {language.message}
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    onChange={(e) => setMessage(e.target.value)}
                    className="custom-control"
                    rows="4"
                    value={message}
                  />
                </Form.Group>
                <Button block onClick={sendSupportEmail} variant="custom">
                  {language.sendemail}
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};
Support.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {})(Support);
