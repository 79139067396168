import React from "react";
import Modal from "react-bootstrap/Modal";
/* import Modal from 'react-modal'; */
import Button from "react-bootstrap/Button";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import "../../styles/sharedProfile.css";

const ImageModal = (props) => {
  const {
    isOpen,
    handleCloseChange,
    img,
    crop,
    onImageLoaded,
    onCropComplete,
    onCropChange,
    handleImgUpload,
    language,
  } = props;

  return (
    <Modal
      show={isOpen}
      /* shouldCloseOnOverlayClick={true} */
      onHide={handleCloseChange}
      centered
      backdrop="static"
      id="custom-modal"
    >
      <Modal.Header>
        <Modal.Title>{language.cropimage}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ReactCrop
          src={img}
          crop={crop}
          ruleOfThirds
          onImageLoaded={onImageLoaded}
          onComplete={onCropComplete}
          onChange={onCropChange}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="imageBtn-reverse" onClick={handleCloseChange}>
          {language.closemodal}
        </Button>
        <Button onClick={handleImgUpload} variant="imageBtn">
          <FontAwesomeIcon className="mr-3" icon={faSave} />
          {language.saveimage}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ImageModal;
