import "react-dates/initialize";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "../src/components/cards/cardStyles.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { positions, Provider as AlertProvider, transitions } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import store from "./store";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { logoutTeacher, setCurrentTeacher } from "./actions/authActions";
import React from "react";

import TeacherLogin from "./components/auth/teacherLogin";
import TeacherRegister from "./components/auth/teacherReg";
import EmailConfirm from "./components/auth/emailConfirm";
import PrincipalRegister from "./components/auth/principalReg";
import PrivateRoute from "./components/private-routes/PrivateRoutes";
import SecurityCode from "./components/auth/securityCode";
import TeacherRegisterRoute from "./components/private-routes/teacherRegisterRoute";
import PrincipalRegisterRoute from "./components/private-routes/principalRegisterRoute";
import ForgotPassword from "./components/auth/forgotPassword";
import ResetPassword from "./components/auth/resetPassword";
import SharedProfile from "./components/profiles/sharedProfile";
import UserRegister from "./components/auth/userRegister";
import AddTempPos from "./components/tempjob/addTempPos";
import Anslagstavlan from "./components/tempjob/anslagstavlan";
import Vikariat from "./components/tempjob/vikariat";
import UserShowTempJobs from "./components/users-only/userShowTempJobs";
import AppliedTempJobs from "./components/users-only/appliedTempJobs";
import ViewJob from "./components/users-only/viewJob";
import ViewUserProfile from "./components/tempjob/viewUserProfile";
import ManageTempJobs from "./components/teachers-only/manageTempJob";
import CreateCode from "./components/principals-only/createCode";
import AddTeacher from "./components/principals-only/addTeacher";
import TeacherAdmin from "./components/principals-only/teacherAdmin";
import PrincipalSettings from "./components/principals-only/principalSettings";
import UserSettings from "./components/users-only/userSettings";
import RequestNewActivationPage from "./components/auth/RequestNewActivationPage";

//Settings pages
import Notifications from "./components/users-only/settings-pages/notifications";
import Security from "./components/users-only/settings-pages/security";

import GDPR from "./components/legal/gdpr";
import Terms from "./components/legal/terms";
//import NotFound from './components/error/notFound';
//import Update from './update';
//super admin stuff
import AuthVerify from "./utils/AuthVerify";
import ErrorPage from "./components/error/ErrorPage";
import EventBus from "./utils/EventBus";
import axios from "axios";

if (localStorage.jwtToken) {
  const token = localStorage.jwtToken;
  setAuthToken(token);
  const decoded = jwt_decode(token);
  store.dispatch(setCurrentTeacher(decoded));
}

// 401 response interceptor
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error.response.status) {
      EventBus.dispatch("logout");
    } else {
      return Promise.reject(error);
    }
  }
);

const logOut = () => {
  store.dispatch(logoutTeacher());
  window.location.reload();
};

/* alert provider options */
const options = {
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: "30px",
  transition: transitions.SCALE,
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promptRefresh: false,
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.error(error, info.componentStack);
  }

  componentDidMount() {
    EventBus.on("logout", () => {
      logOut();
    });
    /*EventBus.on("new-content", () => {
          console.log("new-content")
          toast.info("New version available, please close and reopen Vikaaria.", {
            toastId: "new-content"
          });
        })*/

    return () => {
      EventBus.remove("logout");
      //EventBus.remove("new-content");
    };
  }

  CustomTemplate = ({ style, options, message, close }) => (
    <div style={style}>
      {message}
      <button onClick={close}>X</button>
    </div>
  );

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }
    return (
      <AlertProvider template={AlertTemplate} {...options}>
        <Provider store={store}>
          <Router basename="/app">
            <div className="App">
              {/* <span className="ios-head"></span> */}
              <Route exact path="/" component={TeacherLogin} />
              <Route
                exact
                path="/register/securitycode"
                component={SecurityCode}
              />
              <Route
                exact
                path="/login"
                render={(props) => <TeacherLogin {...props} />}
              />
              <Route exact path="/resetpassword" component={ForgotPassword} />
              <Route
                exact
                path="/new-activation"
                component={RequestNewActivationPage}
              />
              <Route path="/reset/:token" component={ResetPassword} />
              <Route path="/register/user" component={UserRegister} />
              <Route path="/gdpr" component={GDPR} />
              <Route path="/confirm/:id" component={EmailConfirm} />
              <Switch>
                <PrivateRoute
                  exact
                  path="/settings/notifications"
                  component={Notifications}
                />
                <PrivateRoute
                  exact
                  path="/settings/security"
                  component={Security}
                />
                {/* <PrivateRoute exact path="/confirm/:id" component={EmailConfirm} /> */}
                <PrivateRoute exact path="/settings" component={UserSettings} />
                <PrivateRoute exact path="/profile" component={SharedProfile} />
                <PrivateRoute exact path="/jobs/new" component={AddTempPos} />
                <PrivateRoute
                  exact
                  path="/jobs/overview"
                  component={Anslagstavlan}
                />
                <PrivateRoute exact path="/jobs" component={Vikariat} />
                <PrivateRoute
                  exact
                  path="/jobs/all"
                  component={UserShowTempJobs}
                />
                <PrivateRoute
                  exact
                  path="/specific/:jobid"
                  component={ViewJob}
                />
                <PrivateRoute
                  exact
                  path="/profile/:email"
                  component={ViewUserProfile}
                />
                <PrivateRoute
                  exact
                  path="/jobs/personal"
                  component={AppliedTempJobs}
                />
                <PrivateRoute
                  exact
                  path="/manage/:jobid"
                  component={ManageTempJobs}
                />
                <PrivateRoute
                  exact
                  path="/teachers/create"
                  component={AddTeacher}
                />
                <PrivateRoute exact path="/teachers" component={TeacherAdmin} />
                <PrivateRoute
                  exact
                  path="/principal/settings"
                  component={PrincipalSettings}
                />
                <PrivateRoute
                  exact
                  path="/code/creation"
                  component={CreateCode}
                />
                {/*
                <PrivateRoute exact path="/school/add" component={AddSchool} />
                <PrivateRoute
                  exact
                  path="/principal/add"
                  component={AddPrincipal}
                />
                */}
              </Switch>
              <Switch>
                <TeacherRegisterRoute
                  exact
                  path="/register/teacher"
                  component={TeacherRegister}
                />
                <PrincipalRegisterRoute
                  exact
                  path="/register/principal"
                  component={PrincipalRegister}
                />
              </Switch>

              <AuthVerify logOut={logOut} />
            </div>
          </Router>
        </Provider>
      </AlertProvider>
    );
  }
}

export default App;
