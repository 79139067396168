import React, { useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setDefaultDescription } from "../../actions/authActions";
import { toast } from "react-toastify";
import { default as Editpost } from "../navigation/icons/edit_post.svg";

const DefaultDescriptionCard = ({ user, language, ...props }) => {
  const [defaultDescription, setDefaultDescription] = useState(
    user?.defaultDescription ?? ""
  );
  const [isEditing, setIsEditing] = useState(false);

  const handleChange = (event) => {
    setDefaultDescription(event.currentTarget.value);
  };

  const handleSetDefaultDescription = async () => {
    const response = await props.setDefaultDescription({
      email: user.email,
      defaultDescription,
    });

    if (!response.success) {
      toast.warn(
        response?.error ?? "Something unexpected went wrong, please try again."
      );
    }

    setIsEditing(false);
  };

  return (
    <Card
      style={{
        borderRadius: "20px",
        border: "none",
        boxShadow: "0px 0px 24px 0px rgba(13, 17, 77, 0.10)",
        marginTop: "20px",
      }}
    >
      <Card.Body>
        <Row className="f-col">
          <Col>
            <h4 style={{ display: "flex" }}>
              {language.extra} - {language.sos_standardtext}
              <img
                alt=""
                src={Editpost}
                className="edit-icon ml-auto"
                onClick={() => setIsEditing((prev) => !prev)}
              />
            </h4>
          </Col>
        </Row>
        <Form.Group className="mx-0 p-0" controlId="info" as={Col}>
          <Form.Control
            as="textarea"
            className={isEditing ? "" : "custom-control"}
            rows={4}
            disabled={!isEditing}
            onChange={handleChange}
            value={defaultDescription}
          />
        </Form.Group>
        {isEditing && (
          <Row>
            <Col>
              <Button
                className="mt-2"
                variant="deactive"
                block
                size="lg"
                onClick={() => setIsEditing(false)}
              >
                {language.cancel}
              </Button>
              <Button
                block
                variant="custom"
                size="lg"
                onClick={handleSetDefaultDescription}
              >
                {language.savechanges}
              </Button>
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
};
DefaultDescriptionCard.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  setDefaultDescription: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(mapStateToProps, { setDefaultDescription })(
  DefaultDescriptionCard
);
