import { default as Home } from './icons/home.svg'
import { default as Home_active } from './icons/home_active.svg'
/* import { default as Rapporter } from './icons/rapporter.svg'
import { default as Kalender } from './icons/kalender.svg' */
import { default as Setup } from './icons/setup_grey.svg'
import { default as Setup_active } from './icons/setup_lila.svg'
import { default as Teachers } from './icons/teachers.svg'
import { default as Teachers_active } from './icons/teachers_active.svg'
import { default as Applied } from './icons/anmalningar_grey.svg'
import { default as Applied_active } from './icons/anmalningar_lila.svg'
import { default as Vikariat } from './icons/vikariat_grey.svg'
import { default as Vikariat_active } from './icons/vikariat_lila.svg'

import { default as Anslagstavlan } from './icons/calendar-check-regular.svg'
import { default as Anslagstavlan_active } from './icons/calendar-check-regular_active.svg'
import fi from '../../texts/fi.json'
import sv from '../../texts/sv.json'

const settingsSwitch = type => {
    switch (type) {
        case "principal":
            return "/principal/settings"
        default:
            return "/settings"
    }
}

export const TeacherSidebarData = [
    {
        sv_title: sv.nav_bulletin,
        fi_title: fi.nav_bulletin,
        title: 'Anslagstavlan',
        path: '/jobs/overview',
        icon: Anslagstavlan,
        cName: 'nav-text',
        mobiletitle: "Ansökande",
        mobileicon: Anslagstavlan,
        active_icon: Anslagstavlan_active,
        iconStyle: "applied-svg",
        iconClass: 'applied-icon',
        mobIconStyle: "applied-mobile"
    },
    {
        sv_title: sv.nav_tempjobs,
        fi_title: fi.nav_tempjobs,
        title: 'Vikariat',
        path: '/jobs',
        icon: Vikariat,
        active_icon: Vikariat_active,
        cName: 'nav-text',
        iconClass: 'vikariat-icon',
        mobIconStyle: "vikariat-mobile"
    },
    /*   {
          sv_title: sv.nav_calendar,
          fi_title: fi.nav_calendar,
          title: 'Kalender',
          path: '/jobs/overview',
          icon: Kalender,
          cName: 'nav-text'
      }, */
    /* {
        sv_title: sv.nav_settings,
        fi_title: fi.nav_settings,
        title: 'Inställningar',
        path: '/settings',
        icon: Setup,
        active_icon: Setup_active,
        cName: 'nav-text',
        iconClass: "larger-icon"
    } */

]
export const UserSidebarData = [
    /* {
        title: 'Hem',
        path: '/user/dashboard',
        icon: Home,
        cName: 'nav-text'
    }, *//* 
    {
        title: 'Profil',
        path: '/profile',
        icon: <FontAwesomeIcon icon={faUserCircle} />,
        cName: 'nav-text'
    }, */
    {
        sv_title: sv.nav_available,
        fi_title: fi.nav_available,
        title: "Lediga vikariat",
        path: '/jobs/all',
        icon: Vikariat,
        active_icon: Vikariat_active,
        iconStyle: "user-vikariat-svg",
        cName: 'nav-text',
        iconClass: 'vikariat-icon',
        mobIconStyle: "vikariat-mobile"
    },
    {
        sv_title: sv.nav_applied,
        fi_title: fi.nav_applied,
        title: "Mina anmälningar",
        mobiletitle: "Mina ansökningar",
        path: '/jobs/personal',
        icon: Applied,
        active_icon: Applied_active,
        iconStyle: "applied-svg",
        cName: 'nav-text',
        iconClass: "applied-icon",
        mobIconStyle: "applied-mobile"
    },
    /* {
        sv_title: sv.nav_settings,
        fi_title: fi.nav_settings,
        title: 'Inställningar',
        path: '/settings',
        icon: Setup,
        active_icon: Setup_active,
        cName: 'nav-text',
        iconClass: "larger-icon",
        iconStyle: "settings-svg",
    } */
]
export const PrincipalSidebarData = [
    {
        sv_title: sv.nav_bulletin,
        fi_title: fi.nav_bulletin,
        title: 'Anslagstavlan',
        path: '/jobs/overview',
        icon: Anslagstavlan,
        active_icon: Anslagstavlan_active,
        cName: 'nav-text',
        activeSetting: "active-m-svg",
        iconStyle: "applied-svg",
        iconClass: 'applied-icon',
        mobIconStyle: "applied-mobile"
    },
    {
        sv_title: sv.nav_tempjobs,
        fi_title: fi.nav_tempjobs,
        title: 'Vikariat',
        path: '/jobs',
        icon: Vikariat,
        active_icon: Vikariat_active,
        cName: 'nav-text',
        iconClass: 'vikariat-icon',
        activeSetting: "active-m-svg",
        mobIconStyle: "vikariat-mobile"
    },
    {
        sv_title: sv.nav_teachers,
        fi_title: "Opettajat",
        title: 'Lärare',
        path: '/teachers',
        icon: Teachers,
        active_icon: Teachers_active,
        cName: 'nav-text',
        activeSetting: "active-m-svg",
        iconClass: "teacher-icon",
        mobIconStyle: "teacher-mobile"
    },
    /* {
        sv_title: sv.nav_available,
        fi_title: fi.nav_available,
        title: "Lediga vikariat",
        path: '/jobs/all',
        icon: Vikariat,
        active_icon: Vikariat_active,
        iconStyle: "user-vikariat-svg",
        cName: 'nav-text',
        iconClass: 'vikariat-icon',
        mobIconStyle: "vikariat-mobile"
    },
    {
        sv_title: sv.nav_applied,
        fi_title: fi.nav_applied,
        title: "Mina anmälningar",
        mobiletitle: "Mina ansökningar",
        path: '/jobs/personal',
        icon: Applied,
        active_icon: Applied_active,
        iconStyle: "applied-svg",
        cName: 'nav-text',
        iconClass: "applied-icon",
        mobIconStyle: "applied-mobile"
    }, */
    /* {
        sv_title: sv.nav_settings,
        fi_title: fi.nav_settings,
        title: 'Inställningar',
        path: '/principal/settings',
        icon: Setup,
        active_icon: Setup_active,
        cName: 'nav-text',
        activeSetting: "og-color",
        iconClass: "larger-icon"
    }, */
]