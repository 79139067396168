import React from "react";

const gdprSV = () => {
    return (
        <div>
            <section className="inline-body">
                <p>
                    <strong>
                        <span style={{fontSize: "18pt"}}>Dataskyddsbeskrivning</span>
                        <br/>
                        <span style={{fontSize: "14pt"}}>
              I enlighet med dataskyddsförordningen GDPR
            </span>
                        <br/>
                    </strong>
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>1. Registrator</strong>
                </p>
                <p>Softigo Ab</p>
                <p>FO-nummer:&nbsp;3336019-2&nbsp;</p>
                <p>&nbsp;&nbsp;</p>
                <p>
                    <strong>Kontaktuppgifter i ärenden angående registret</strong>
                </p>
                <p>
                    Softigo Ab
                    <br/>
                    Rådhusgatan 19, 65100 Vasa
                </p>
                <p>Tfn: +358 50 577 3854</p>
                <p>
                    E-post: &nbsp;<a href="mailto:info@softigo.fi">info@softigo.fi</a>
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>2. De registrerade</strong>
                </p>
                <p>
                    De personer som registrerat sig i Softigo&nbsp;Ab:s Vikaaria
                    applikation.
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>3. Samlande av personuppgifter</strong>
                </p>
                <p>Personuppgifter behandlas på grundval av samtycke.</p>
                <p>&nbsp;</p>
                <p>
                    Registratorn samlar in personuppgifter om den registrerade när hen
                </p>
                <p>
                    ● registrerar ett konto hos registratorn
                    <br/>
                    ● använder registratorns tjänster
                    <br/>
                    ● kontaktar registrator via e-post, telefon eller registrators sociala mediekanaler
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>4. Delning av personuppgifter</strong>
                </p>
                <p>
                    Registratorn behandlar personuppgifter endast för följande fördefinierade ändamål:
                    Applikationens funktionalitet, diagnostik, hantering av kundrelationer samt marknadsföring
                    av registratorns tjänster. Personuppgifter delas inte med tredje part, förutom när det krävs
                    för att tillhandahålla registratorns tjänster eller uppfylla juridiska skyldigheter. Tredje parter
                    inkluderar: Skolor, förskolor och daghem som söker vikarier, betalningsleverantörer samt
                    myndigheter när det krävs enligt lag.
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>5. Kundregistrets datainnehåll</strong>
                </p>
                <p>
                    Kundregistret innehåller följande personuppgifter som krävs vid registrering: förnamn,
                    efternamn, modersmål, adress, e-postadress, telefonnummer. Registret innehåller den
                    registrerades profilbild samt all övrig frivillig information som den registrerade uppgivit för
                    att utöka sin personliga profil.
                    Registret innehåller även IP-adress och information om den registrerades användning av
                    registratorns webbplatser. Registratorn kan även samla in utbildnings- och yrkeshistorik,
                    certifikat, intyg (dock inte straffregisterutdrag eller skattekort) samt referenser.
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>5.1 Straffregisterutdrag</strong>
                </p>
                <p>
                    För att säkerställa en säker arbetsmiljö kan skolor, förskolor och daghem som använder
                    registratorns tjänster begära att vikarien tillhandahåller ett straffregisterutdrag relaterat till
                    arbete med barn. Utdraget bör inte vara äldre än 6 månader vid tidpunkten för anställning.
                    Registratorn begär, uppbevarar eller behandlar aldrig den registrerades straffregisterutdrag
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>6. Användning av personuppgifter</strong>
                </p>
                <p>
                    Registratorn använder den registrerades personuppgifter för att:
                </p>
                <p>
                    ● tillhandahålla och förbättra egna tjänster
                    <br/>
                    ● hantera den registrerades konto
                    <br/>
                    ● matcha vikarier med relevanta vikariat
                    <br/>
                    ● kommunicera med den registrerade och besvara hens frågor
                    <br/>
                    ● skicka nyhetsbrev och marknadsföringsmaterial (med den registrerades
                    samtycke)
                    <br/>
                    ● uppfylla registratorns juridiska skyldigheter
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>7. Den registrerades rättigheter</strong>
                </p>
                <p>
                    Den registrerade kan motsätta sig behandling av personuppgifter om hen
                    finner att personuppgifter har behandlats i strid mot gällande
                    dataskyddslagstiftning.
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>7.1 Kontrollrätt</strong>
                </p>
                <p>
                    Den registrerade har rätt att kontrollera vilka uppgifter om hen har registrerats.
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>7.2 Rätt till korrigering av information</strong>
                </p>
                <p>
                    Den registrerade har rätt att begära korrigering av felaktiga eller bristfälliga uppgifter om
                    hen.
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>7.3 Invändningsrätt</strong>
                </p>
                <p>
                    Den registrerade kan motsätta sig behandling av personuppgifter om hen finner att
                    personuppgifter har behandlats i strid mot gällande dataskyddslagstiftning.
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>7.4 Förbud mot direkt marknadsföring</strong>
                </p>
                <p>
                    Den registrerade har rätt att förbjuda att hens personuppgifter används för
                    direktmarknadsföring.
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>7.5 Rätt till radering</strong>
                </p>
                <p>
                    Den registrerade har rätt att begära att uppgifter som avser hen raderas, ifall bevarandet av
                    uppgifterna inte är nödvändigt. Registratorn behandlar en begäran om radering, varefter
                    registratorn antingen raderar uppgifterna eller ger en motiverad anledning till att inte göra
                    det. Vänligen notera att registratorn kan ha en rättslig- eller avtalsenlig skyldighet att inte
                    radera de begärda uppgifterna. Enligt bokföringslagen (kapitel 2, 10 §) är registratorn skyldig
                    att bevara bokföringsmaterial i minst 10 år. Av denna anledning kan bokföringsmaterial inte
                    raderas innan tidsfristen löpt ut.
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>7.6 Återkallande av samtycke</strong>
                </p>
                <p>
                    Om behandlingen av personuppgifter endast grundar sig på samtycke och inte t.ex. på ett
                    kundförhållande eller ett medlemskap, kan den registrerade återkalla sitt samtycke. Den
                    registrerade kan överklaga beslutet hos dataskyddsmyndigheten. I oklara fall har den
                    registrerade rätt att begränsa behandlingen av sina personuppgifter tills saken kan avgöras.
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>7.7 Rätt att överklaga</strong>
                </p>
                <p>
                    Den registrerade har rätt att lämna in ett klagomål till dataskyddsmyndigheten, ifall hen
                    anser att registratorn har behandlat personuppgifter på ett sätt som strider mot gällande
                    dataskyddslagstiftning. Dataombudsmannens byrås kontaktuppgifter:{" "}
                    <a href="http://www.tietosuoja.fi/fi/index/yhteystiedot.html">
                        www.tietosuoja.fi/fi/index/yhteystiedot.html
                    </a>
                    <br/>
                    En begäran skickas till adressen: &nbsp;<a href="mailto:info@softigo.fi">info@softigo.fi</a>
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>8. Regelmässiga uppgiftskällor</strong>
                </p>
                <p>
                    Kundinformation fås i regel från den registrerade själv i
                    registreringsskedet.
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>9. Regelmässigt utlämnande av uppgifter</strong>
                </p>
                <p>
                    Tjänsteleverantörer som regelbundet används är Google Analytics, One Signal, Digital Ocean
                    och Supabase. Tjänsteleverantörerna försäkrar att de följer dataskyddsbestämmelserna.
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>10. Behandlingens varaktighet</strong>
                </p>
                <p>
                    Personuppgifter behandlas i huvudsak så länge som den registrerade är registrerad.
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>11. Personuppgifternas behandlare</strong>
                </p>
                <p>
                    Personuppgifterna behandlas av registratorn och dennes anställda. Registratorn kan också
                    delvis outsourca behandlingen av personuppgifter till en tredje part, varvid registratorn
                    genom avtalsarrangemang säkerställer att personuppgifterna behandlas sakligt och enligt
                    gällande dataskyddsförordning.
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>12. Överföring av personuppgifter utanför EU</strong>
                </p>
                <p>
                    Personuppgifter överförs inte utanför EU eller det Europeiska ekonomiska
                    samarbetsområdet.
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>13. Automatiserat beslutsfattande och profilering</strong>
                </p>
                <p>
                    Personuppgifter används inte till automatiskt beslutsfattande eller
                    profilering.
                </p>
                <p>&nbsp;</p>
                <p>
                    <strong>14. Ändringar i dataskyddsbeskrivningen</strong>
                </p>
                <p>
                    Registratorn kan komma att uppdatera denna integritetspolicy vid behov. Registratorn
                    rekommenderar att den registrerade regelbundet granskar denna sida för att hålla sig
                    informerad om eventuella ändringar.
                </p>
                <p>&nbsp;</p>
                <p>Vasa 30.7.2024</p>
            </section>
        </div>
    );
};

export default gdprSV;
