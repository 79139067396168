import React from "react";
import "react-dates/initialize";
import DatePicker, { registerLocale } from "react-datepicker";
import fi from 'date-fns/locale/fi'
import sv from 'date-fns/locale/sv'

import "react-datepicker/dist/react-datepicker.css";
registerLocale('sv', sv)
registerLocale('fi', fi)

const PickEndDate = props => {
    const { endDate, handleEndDateChange, startDate, language } = props;
    /* const [startDate, setStartDate] = useState(new Date()); */

    const lang = () => {
        if(language.fi){
            return "fi"
        } else {
            return "sv"
        }
    }

    return (
        <DatePicker 
            selected={endDate} 
            locale={lang()} 
            dateFormat="dd.MM.yyyy" 
            minDate={startDate} 
            onChange={handleEndDateChange}
            className="form-control custom-date"
            withPortal
        />
    );
}

export default PickEndDate;