import { Link } from "react-router-dom";

export default [
    {
        question: `Vad är Vikaaria?`,
        answer: `Vikaaria är en unik tjänst som notifierar dig direkt skolor behöver vikarier. Du navigerar 
        enkelt bland vikariat och kan snabbt ställa upp och vikariera. Med hjälp av Vikaaria når 
        skolor i behov av vikarier snabbt och effektivt ut till dig.        
        `,
        question_fi: `Mikä Vikaaria on?`,
        answer_fi: `Vikaaria on ainutlaatuinen palvelu joka ilmoittaa sinulle koulujen tarvitessa sijaisia. Selaat ja luet helposti sijaisuuksista ja voit kätevästi ilmoittautua sijaiseksi. Vikaarian avulla sijaisia tarvitsevat koulut löytävät sinut nopeasti ja tehokkaasti. `
    },
    {
        question: `Vem har skapat Vikaaria`,
        answer: `Vi som skapat tjänsten Vikaaria är erfarna pedagoger och företagare inom marknadsföring
        och programmering samt mediabranschen.`,
        question_fi: `Kuka on luonut Vikaarian?`,
        answer_fi: `Me Vikaaria -palvelun luoneet olemme pitkän kokemuksen omaavia pedagogeja ja yrittäjiä markkinoinnissa ja ohjelmoinnissa sekä media-alalla.`
    },
    {
        question: `Kostar det att använda Vikaaria tjänsten?`,
        answer: `Tjänsten är kostnadsfri för dig.`,
        question_fi: `Onko Vikaaria palvelu maksullinen?  `,
        answer_fi: `Palvelu on sinulle maksuton. `
    },
    {
        question: `Villken nytta har jag av att vikariera?`,
        answer: `Genom att vikariera ökar du din inkomst och samlar arbetserfarenhet, som är till din fördel 
        när du söker framtida arbeten. Vikariat är en ypperlig möjlighet för dig att knyta kontakter 
        och bygga ditt nätverk. Redan under studietiden får du insyn i arbetet i skola och kan 
        omvandla och förankra din teoretiska kunskap i praktik. Dessutom blir du populär för 
        problemlösare uppskattas alltid.
        `,
        question_fi: `Miten hyödyn sijaistamisesta?  `,
        answer_fi: `Työskentelemällä sijaisena lisäät tulojasi ja keräät työkokemusta, joka hyödyttää sinua etsiessäsi tulevia työpaikkoja. Sijaistaminen on erinomainen tilaisuus luoda yhteyksiä ja rakentaa verkostoasi. Jo opintojen aikana saat tietoa koulun työstä ja voit muuttaa ja ankkuroida teoreettista tietämystäsi käytäntöön. Lisäksi sinusta tulee suosittu koska ongelmanratkaisijaa arvostetaan aina.`
    },
    {
        question: `Var hittar jag appen?`,
        answer: `Vikaaria, som är en progressiv webbapplikation, kan laddas ner från PlayStore eller 
        app.vikaaria.fi. Du kan även använda Vikaaria på din dator. Öppna Vikaaria här, 
        app.vikaaria.fi. Du hittar länken även på vikaaria.fi hemsidan. Notera att iPhone i nuläget 
        inte kan ta emot notiser från progressiva webbapplikationer.
        `,
        question_fi: `Mistä löydän sovelluksen? `,
        answer_fi: `Vikaaria, joka on progressiivinen verkkosovellus, on ladattavissa PlayStoresta tai app.vikaaria.fi:stä. Voit käyttää Vikaariaa myös tietokoneellasi. Avaa Vikaaria tästä, app.vikaaria.fi. Linkin löydät myös vikaaria.fi -sivustolta. Huomaa, että iPhone ei tällä hetkellä voi vastaanottaa ilmoituksia progressiivisista verkkosovelluksista.`
    },
    {
        question: `Hur registrerar jag mig?`,
        answer: `Du registrerar dig via appen. Du uppger namn, kontaktuppgifter, modersmål och landskap 
        i vilka du önskar ta emot vikariat. Du registrerar dig som studerande, lärare eller annan. 
        För att slutföra registreringen loggar du inom 24 timmar in på Vikaaria appen. Ifall 
        inloggning inte sker inom 24 timmar låses ditt konto och du behöver kontakta supporten. Vi
        rekommenderar att du redigerar din profil och vid tilläggsuppgifter uppger språkkunskaper,
        studier, examen och arbetserfarenhet. Vi rekommenderar att du laddar upp en profilbild. 
        Informationen underlättar för arbetsgivare att lära känna dig och anställa dig.`,
        question_fi: `Kuinka rekisteröidyn? `,
        answer_fi: `Rekisteröidyt sovelluksen kautta. Ilmoitat nimesi, yhteystietosi, äidinkielesi ja alueet joissa haluat vastaanottaa sijaisuuksia. Ilmoittaudut opiskelijaksi, opettajaksi tai muuksi. Viimeistele rekisteröinti kirjautumalla Vikaaria -sovellukseen 24 tunnin sisällä. Jos sisäänkirjautumista ei tapahdu 24 tunnin kuluessa, tilisi lukitaan ja sinun on otettava yhteyttä tukeen. Suosittelemme, että muokkaat profiiliasi ja ilmoitat lisätietojen yhteydessä kielitaidon, opintojen, tutkinnon ja työkokemuksen. Suosittelemme, että lataat profiilikuvan. Tiedot auttavat työnantajia tutustumaan sinuun ja palkkaamaan sinut. `
    },
    {
        question: `Vem kan registrera sig som vikarie i Vikaaria?`,
        answer: `Vi ser gärna att du har en anknytning till pedagogik och undervisning antingen genom 
        studier, examen eller tidigare arbetserfarenhet inom området.`,
        question_fi: `Kuka voi rekisteröityä sijaiseksi?`,
        answer_fi: `Näemme mielellämme, että sinulla on yhteys pedagogiikkaan ja opetukseen joko opintojen, tutkinnon tai aiemman alan työkokemuksen kautta. `
    },
    {
        question: `Hur får jag vetskap om nya vikariat?`,
        answer: `Meddelande om nytt vikariat sänds automatiskt till din e-postadress. Notera att e-postmeddelande är möjligt att stänga av / lägga på. Du kan läsa om nya vikariat i appen, 
        på Lediga vikariat. Vi rekommenderar att du godkänner notiser eftersom du på så vis 
        snabbare uppmärksammas på nya vikariat. Notera att Vikaaria är en progressiv 
        webbapplikation och att iPhone i nuläget inte kan ta emot notiser från progressiva 
        webbapplikationer.`,
        question_fi: `Miten saan tietoa uusista sijaisuuksista?  `,
        answer_fi: `Ilmoitus uudesta sijaisuudesta lähetetään automaattisesti sähköpostiosoitteeseesi. Huomaa, että sähköpostiviestejä on mahdollista kytkeä päälle / pois päältä. Uusista sijaisuuksista voit lukea sovelluksessa, kohdassa Sijaisuudet. Suosittelemme, että hyväksyt ilmoitukset, sillä näin huomaat nopeammin uudet sijaisuudet. Huomaa, että Vikaaria on progressiivinen verkkosovellus ja että iPhone ei tällä hetkellä voi vastaanottaa ilmoituksia progressiivisista verkkosovelluksista. `
    },
    {
        question: `Hur anmäler jag mig till nya vikariat?`,
        answer: `På Lediga vikariat kan du läsa om vikariaten (skola, adress, tidpunkt, yrkesgrupp för vilken
            vikarie söks, årskurs, ämnen, tilläggsinformation) och anmäla dig. Du öppnar och läser om
            ett vikariat och anmäler dig enkelt med ett klick. Du bekräftar din anmälan med ytterligare 
            ett klick. Ifall ett vikariat du anmäler dig till sammanfaller delvis eller helt med vikariat du 
            redan valts till, uppmärksammas du på detta. Vikariat du anmält dig till flyttas automatiskt 
            till Mina anmälningar.`,
        question_fi: `Kuinka ilmoittaudun uusiin sijaisuuksiin?`,
        answer_fi: `Sijaisuudet -kohdassa voit lukea sijaisuuksista (koulu, osoite, aika, ammattiryhmä johon sijaista haetaan, vuosi, oppiaineet, lisätiedot) ja ilmoittautua. Avaat ja luet sijaisuudesta ja ilmoittaudut helposti klikkaamalla kerran. Vahvistat ilmoittautumisesi klikkaamalla toisen kerran. Jos sijaisuus, johon ilmoittaudut, osuu osittain tai kokonaan toiseen sijaisuuteen, johon sinut on jo valittu sijaiseksi, sinulle ilmoitetaan tästä. Sijaisuudet, joihin olet ilmoittautunut, siirtyvät automaattisesti Ilmoittautumiset -kohtaan. `
    },
    {
        question: `Vad betyder vikariat märkta med orange?`,
        answer: `Vikariat, som du ännu inte öppnat och läst om, är märkta med orange.`,
        question_fi: `Mitä tarkoittaa oranssilla merkitty sijaisuus?`,
        answer_fi: `Sijaisuudet, joista et vielä ole lukenut, on merkitty oranssilla. `
    },
    {
        question: `Vad betyder vikariat märkta med violett?`,
        answer: `Vikariat, som du öppnat och läst om, är märkta med violett.`,
        question_fi: `Mitä tarkoittaa violetilla merkitty sijaisuus? `,
        answer_fi: `Sijaisuudet, joista olet lukenut, on merkitty violetilla.`
    },
    {
        question: `Vad betyder vikariat märkta med rött?`,
        answer: `Brådskande vikariat är märkta med rött. Skolor kan behöva vikarie med mycket kort varsel och publicerar då brådskande vikariat. Vikariatet är vanligen mycket kort beskrivet. Detaljerad information om vikariatet ges till den vikarie som väljs till vikariatet.`,
        question_fi: `Mitä tarkoittaa punaisella merkitty sijaisuus?`,
        answer_fi: `Kiireelliset sijaisuudet on merkitty punaisella. Koulut saattavat tarvita sijaisen hyvin lyhyellä varoitusajalla ja julkaista siten kiireellisen sijaisuuden. Sijaisuus kuvataan yleensä hyvin lyhyesti. Tarkemmat tiedot sijaisuudesta annetaan sijaisuuteen valitulle sijaiselle.`
    },
    {
        question: `Kan jag anmäla mig till flera vikariat samtidigt?`,
        answer: `Du kan anmäla dig till flera vikariat och på så sätt öka dina möjligheter att väljas till vikariat. Ifall vikariat du anmäler dig till sammanfaller delvis eller helt med vikariat du redan valts till, uppmärksammas du på detta. När du väljs till ett vikariat avaktiveras automatiskt anmälningar du gjort till andra vikariat, som sammanfaller med det vikariat du valts till.         
        `,
        question_fi: `Voinko ilmoittautua useampaan sijaisuuteen samanaikaisesti?`,
        answer_fi: `Voit ilmoittautua useisiin sijaisuuksiin ja näin lisätä mahdollisuuksiasi tulla valituksi sijaisuuteen. Jos sijaisuus, johon ilmoittaudut, osuu osittain tai kokonaan toiseen sijaisuuteen, johon sinut on jo valittu sijaiseksi, sinulle ilmoitetaan tästä. Kun sinut valitaan sijaisuuteen, ilmoittautumisesi muihin sijaisuuksiin, jotka osuvat samaan aikaan tämän kanssa, poistetaan automaattisesti.  `
    },
    {
        question: `Kan jag anmäla mig till ett vikariat som redan börjat?`,
        answer: `Så länge ett vikariat är ledigt kan du anmäla dig till det.`,
        question_fi: `Voinko ilmoittautua jo alkaneeseen sijaisuuteen? `,
        answer_fi: `Niin kauan kuin sijaisuus on vapaa, voit ilmoittautua siihen. `
    },
    {
        question: `Hur vet jag att jag valts till vikariatet?`,
        answer: `Meddelande om att du valts till vikariat sänds automatiskt till din e-postadress. Notera att e-postmeddelande är möjligt att stänga av / lägga på. I appen, på Mina anmälningar, ändras färgen, på vikariatet du valts till, till helgrön. Skolan kontaktar dig snarast. Vi rekommenderar att du godkänner notiser eftersom du på så vis snabbare uppmärksammas på att du valts till vikarie. Notera att Vikaaria är en progressiv webbapplikation och att iPhone i nuläget inte kan ta emot notiser från progressiva webbapplikationer. `,
        question_fi: `Mistä tiedän, että minut on valittu sijaiseksi? `,
        answer_fi: `Ilmoitus siitä, että sinut on valittu sijaiseksi, lähetetään automaattisesti sähköpostiosoitteeseesi. Huomaa, että sähköpostiviestejä on mahdollista kytkeä päälle / pois päältä. Sovelluksessa, Ilmoittautumiset -kohdassa, sijaisuus, johon sinut on valittu sijaiseksi, muuttuu vihreäksi. Koulu ottaa sinuun yhteyttä mahdollisimman pian. Suosittelemme, että hyväksyt ilmoitukset, sillä näin huomaat nopeammin että sinut on valittu sijaiseksi. Huomaa, että Vikaaria on progressiivinen verkkosovellus ja että iPhone ei tällä hetkellä voi vastaanottaa ilmoituksia progressiivisista verkkosovelluksista. `
    },
    {
        question: `Hur vet jag att jag inte blivit vald till vikariatet?`,
        answer: `I appen, på Mina anmälningar, ändras färgen på vikariatet i fråga till helrött.`,
        question_fi: `Mistä tiedän, ettei minua ole valittu sijaiseksi? `,
        answer_fi: `Sovelluksessa, Ilmoittautumiset -kohdassa, kyseisen sijaisuuden väri muuttuu kokonaan punaiseksi.`
    },
    {
        question: `När får jag veta om jag blivit vald till vikariatet?`,
        answer: `Vikariat besätts vanligen så snart som möjligt.`,
        question_fi: `Milloin saan tietää, onko minut valittu sijaiseksi?`,
        answer_fi: `Sijaisuudet miehitetään yleensä mahdollisimman pian. `
    },
    {
        question: `Kan jag annullera min anmälning till ett vikariat?`,
        answer: `Du kan annullera din anmälning så länge du inte valts till vikariatet i fråga. Om du valts till vikariatet, men av vägande skäl är tvungen att annullera bör du omedelbart vara i kontakt med skolan. Du vill respektera arbetsgivaren och ge ett gott intryck. Observera att annulleringar utan vägande skäl kan ge en negativ bild av dig. Om du är osäker på din möjlighet att vikariera är det bättre att du avvaktar innan du anmäler dig. `,
        question_fi: `Voinko peruuttaa ilmoittautumiseni?`,
        answer_fi: `Voit peruuttaa ilmoittautumisesi niin kauan kuin sinua ei ole valittu kyseiseen sijaisuuteen. Jos sinut on valittu sijaiseksi, mutta joudut pakottavista syistä peruuttamaan, sinun tulee olla välittömästi yhteydessä kouluun. Haluat kunnioittaa työnantajaa ja antaa hyvän vaikutelman. Huomaa, että peruutukset ilman hyvää syytä voivat antaa sinusta negatiivisen kuvan. Jos olet epävarma mahdollisuuksistasi sijaistaa, on parempi, että odotat ennen ilmoittautumista. `
    },
    {
        question: `Vad behöver jag med mig till arbetsplatsen?`,
        answer: `Identitetsbevis, kopia av skattekort och bankinformation. Skolan kan ytterligare begära att få se exempelvis studiekort, examensbevis, arbetsintyg eller utdrag ur straffregister.`,
        question_fi: `Mitä tarvitsen mukaan työpaikalle? `,
        answer_fi: `Henkilötodistus, kopio verokortista ja pankkitiedot. Koulu voi lisäksi pyytää näkemään esimerkiksi opiskelijakortin, päättötodistuksen, työtodistuksia tai otteen rikosrekisteristä.`
    },
    {
        question: `Hur sker anställningen och löneutbetalningen?`,
        answer: `Skolan och kommunen/staden administrerar anställningen och ansvarar för löneutbetalningen. Vikaaria (Listit Software Oy) som tredje part enbart sammanför vikarien med skolan.  
        `,
        question_fi: `Miten työsuhde ja palkanmaksu tapahtuvat?  `,
        answer_fi: `Koulu ja kunta/kaupunki hallinnoivat työllistämistä ja vastaavat palkanmaksusta. Vikaaria (Listit Software Oy) kolmantena osapuolena ainoastaan tuo sijaisen yhteen koulun kanssa.`
    },
    {
        question: `Måste appen vara konstant aktiv för att jag inte ska missa vikariat? `,
        answer: `Appen fungerar även om du stänger den.`,
        question_fi: `Pitääkö sovelluksen olla jatkuvasti aktiivinen, jotta en jää paitsi uusista sijaisuuksista?`,
        answer_fi: `Sovellus toimii, vaikka suljet sen. `
    },
    {
        question: `Hur uppdaterar jag appen?   `,
        answer: `Appen uppdateras automatiskt.`,
        question_fi: `Miten päivitän sovelluksen? `,
        answer_fi: `Sovellus päivitetään automaattisesti. `
    },
    {
        question: `Hur raderar jag mitt konto?`,
        answer: `Du kan radera ditt konto i din egen Profil.`,
        question_fi: `Miten poistan tilini?`,
        answer_fi: `Voit poistaa tilisi kohdassa Profiili.`
    },
]