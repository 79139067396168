import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { login } from "../../actions/authActions";
import { withRouter } from 'react-router-dom';
import logo from '../../images/logo.png';
import '../../styles/globalBoostrapEdits.css';
import '../../styles/login.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Cookies from 'js-cookie';
import LanguagePicker from '../func-components/languagePicker';
import { withAlert } from 'react-alert';
import NewAccountModal, {handleNewAccountModalChange} from "./NewAccountModal";


class TeacherLogin extends Component {
    constructor() {
        super();
        this.state = {
            email: "",
            password: "",
            errors: {},
            emailnotfound: "",
            message: "",
            userEmail: Cookies.getJSON('_email'),
            language: Cookies.getJSON('_lang') ? Cookies.getJSON('_lang') : "fi",
            newReg: false,
            show: false,
        };
    };


    /* TODO: En sak  om du i registrering klickar på användarvillkor så blir du läst om du är på ipad. Kommer inte bakåt eller stänga av så du skulle komma tillbaka till registreringen. Man förlorar all info och måste börja om från början. */

    componentDidMount() {
        if (this.props.auth.isAuthenticated) {
            this.props.history.push("/jobs/all");
        };
        if (this?.props?.location?.state?.newReg) {
            this.setState({ newReg: true })
        }
    };

    static getDerivedStateFromProps(nextProps, state) {
        /* if(nextProps.auth.isAuthenticated){
            nextProps.history.push("/jobs/overview")
        } */
        if (nextProps.errors) {
            return {
                errors: nextProps.errors
            }
        };
        if (nextProps.emailnotfound) {
            return {
                emailnotfound: nextProps.emailnotfound
            }
        }
        if(nextProps.message){
            /* const alert = this.props.alert
            alert.info(<div style={{ fontSize: "1.4em"}}>{nextProps.message}</div>, {timeout: 6000}) */
            return{
                message: nextProps.message
            }
        }
    }

    handleLanguage = (e) => {
        this.setState({ language: e.currentTarget.id }, () => {
            Cookies.set('_lang', this.state.language)
        })
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSubmit = e => {
        e.preventDefault();

        const loginData = {
            email: this.state.email.toLowerCase() || this.state.userEmail.toLowerCase(),
            password: this.state.password
        };

        this.props.login(loginData, this.props.history);
    };

    changeEmail = () => {
        this.setState({ userEmail: undefined })
    }

    emailRender = () => {
        if (this.state.userEmail === undefined) {
            return (
                <Form.Group>
                    <Form.Control
                        size="lg"
                        id="email"
                        type="email"
                        placeholder={this.state.language === "fi" ? "Sähköposti" : "E-post"}
                        value={this.state.email}
                        onChange={this.onChange}
                        autoCapitalize="none"
                    />
                </Form.Group>
            )

        } else {
            return (
                <p
                    className="saved-email">{this.state.userEmail} <Link
                        className="change-email" to="#" onClick={this.changeEmail}
                    >{this.state.language === "fi" ? "vaihda" : "ändra"}</Link></p>
            )
        }
    }

    /* "Kirjaudu sisään 24 t sisällä, tai tilisi lukkiutuu! " : "Logga in inom 24h, annars låses ditt konto!" */
    onNewReg = () => {
        const alert = this.props.alert
        this.setState({ newReg: false }, () => {
            alert.info(<div style={{
                fontSize: "1.4em",
            }}>{this.state.language === "fi" ? "Please check your email for account verification." : "Please check your email for account verification."}</div>, {
                timeout: 6000,
            })
        })
        //this.setState({show: true})
    }

    render() {
        const { errors, language, newReg, show } = this.state;
        return (
            <div className="body">
                <Container className="vertical-center">
                    {newReg && this.onNewReg()}
                    <Row className="justify-content-md-center text-center">
                        <Col xs={12} sm={4} md={4}>
                            <img src={logo} alt="Logo" className="" />
                        </Col>
                    </Row>

                    <Row className="mt-3 justify-content-sm-center text-center">
                        <Col md="4">
                            <LanguagePicker
                                language={language}
                                handleLang={this.handleLanguage}
                            />
                        </Col>
                    </Row>

                    <Form noValidate onSubmit={this.onSubmit}>
                        <Row className="justify-content-sm-center" style={{marginTop: "2rem"}}>
                            <Col md="4">
                                {this.emailRender()}
                            </Col>
                        </Row>
                        <Row className="justify-content-sm-center">
                            <Col md="4">
                                <Form.Group>
                                    <Form.Control
                                        size="lg"
                                        onChange={this.onChange}
                                        value={this.state.password}
                                        id="password"
                                        type="password"
                                        placeholder={language === "fi" ? "Salasana" : "Lösenord"}
                                    />
                                    <span className="text-danger lt">
                                        {errors.password}
                                        {errors.passwordincorrect}
                                        {this.state.errors.email}
                                        {this.state.errors.emailnotfound}
                                        {this.state.errors.message}
                                    </span>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="justify-content-sm-center mt-3">
                            <Col md="4"><Button variant="custom" size="lg" block type="submit">{language === "fi" ? "Kirjaudu sisään" : "Logga in"}</Button></Col>
                        </Row>
                        <Row className="justify-content-sm-center mt-3 text-center">
                            <Col md="4">
                                <p className="pre-text text-muted">{language === "fi" ? "Ei tiliä?" : "Inget konto?"} <Link className="forgot-link" to="/register/user">{language === "fi" ? "Rekisteröidy nyt!" : "Registrera dig!"}</Link></p>
                            </Col>
                        </Row>
                        <Row className="justify-content-sm-center text-center">
                            <Col md="4">
                                <p className="pre-text text-muted">{language === "fi" ? "Opettaja?" : "Lärare?"} <Link className="forgot-link" to="/register/teacher">{language === "fi" ? "Rekisteröidy nyt!" : "Registrera dig!"}</Link></p>
                            </Col>
                        </Row>
                        <Row className="justify-content-sm-center text-center">
                            <Col md="4">
                                <p className="forgot-link pe-cursor"><Link className="forgot-link" to="/new-activation">{language === "fi" ? "Lähetä uusi aktivointilinkki" : "Skicka ny aktiveringslänk"}</Link></p>
                            </Col>
                        </Row>
                        <Row className="justify-content-sm-center text-center">
                            <Col md="4">
                                <Link className="forgot-link" to="/resetpassword">{language === "fi" ? "Unohditko salasanasi?" : "Glömt ditt lösenord?"}</Link>
                            </Col>
                        </Row>
                        {/* <hr className="mt-5"></hr>
                        <Row className="justify-content-sm-center mt-4">
                            <Col md="4">
                                <div className="re-captcha">
                                    This site is protected by reCAPTCHA and the Google
                                    <a href="https://policies.google.com/privacy"> Privacy Policy </a>
                                    and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                                </div>
                            </Col>
                        </Row> */}
                    </Form>
                </Container>
            </div >
        );
    };
};

TeacherLogin.propTypes = {
    login: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { login }
)(withRouter(withAlert()(TeacherLogin)));
