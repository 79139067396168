import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import CustomNavbar from "../navigation/customNavbar";
import {
  addApplicant,
  incViewCount,
  logoutTeacher,
  inactivateJob,
  reactivateJob,
  hideJobForUser,
  blockSchool,
  getAdminmessages,
} from "../../actions/authActions";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import { io } from "socket.io-client";
import "../../styles/userShowTempJobs.css";
import "./userCardStyling.css";
import Spinner from "../func-components/spinner";
import Sort from "../func-components/sort";
import MobileLayout from "../mobile/mobileLayout";
import LongCard from "../cards/longCard";
import BlockCard from "../cards/blockCard";
import { default as Vikariat } from "../navigation/icons/chalkboard-teacher-solid_grey.svg";
import { default as BellImportant } from "./icons/notification_important.svg";
import { default as IosShare } from "./icons/iosshare.svg";
import { default as SOSIcon } from "./icons/sos.svg";
import { default as SeenIcon } from "./icons/sedd.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import fi from "../../texts/fi.json";
import sv from "../../texts/sv.json";

class UserShowTempJobs extends Component {
  _isMounted = false;
  constructor() {
    super();
    this.state = {
      tempJobs: [],
      inactiveJobs: [],
      isLoading: true,
      isOpen: false,
      specificJob: {},
      sidebar: !window.matchMedia("(max-width: 1450px)").matches,
      listSort: true,
      noJobs: false,
      resMsg: "",
      mobile: false,
      promptRefresh: false,
      language: {},
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.auth.teacher.typeOfUser !== "user") {
      this.props.history.push("/jobs/overview");
    }

    this._isMounted && this.getLang(this.props.auth.teacher);

    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLocaleLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };

    const isInStandaloneMode = () =>
      "standalone" in window.navigator && window.navigator.standalone;

    if (isIos() && !isInStandaloneMode()) {
      /* NOTE: removed prompt to add to home screen for now */
      /* this.iosPopup() */
    }

    const handler = () => {
      let match = window.matchMedia("(max-width: 600px)").matches;
      if (match && this._isMounted) {
        this.setState({ listSort: false, mobile: true });
      } else {
        this.setState({ mobile: false });
      }
    };
    handler();
    window.addEventListener("resize", handler);

    const data = {
      email: this.props.auth.teacher.email,
    };
    this._isMounted && this.getJobs(data);



    const socket = io();

    socket.on("jobCreated", () => {
      this._isMounted && this.getJobs(data);
    });
    socket.on("updateapplied", () => {
      this._isMounted && this.getJobs(data);
    });

    const user_email = this.props.auth.teacher.email;
    const user_type = this.props.auth.teacher.typeOfUser;
    if (user_type === "user" && this._isMounted) {
      var OneSignal = window.OneSignal || [];
      OneSignal.push(function () {
        OneSignal.setExternalUserId(user_email);
        OneSignal.sendTag("user_type", user_type);
        OneSignal.sendTag("email", user_email);
      });
    }
  }

  getLang = (user) => {
    if (this._isMounted && user.siteLang === 2) {
      this.setState({ language: sv });
    } else if (this._isMounted && user.siteLang === 1) {
      this.setState({ language: fi });
    } else {
      this._isMounted && this.setState({ language: fi });
    }
  };

  iosToast = ({ closeToast, toastProps }) => (
    <div>
      <p
        style={{
          fontSize: "1.6em",
        }}
      >
        {this.state.language.iosaddapp1}{" "}
        <img alt="" style={{ width: "24px" }} src={IosShare} />{" "}
        {this.state.language.iosaddapp2}
      </p>
    </div>
  );
  iosPopup = () => {
    toast.info(this.iosToast, {
      autoClose: 10000,
    });
  };

  getAdminMsg = () => {
    const adminPromise = this.props.getAdminmessages();
    return adminPromise.then(data => data)
  }

  getJobs = (data) => {
    if (this._isMounted && this.props.auth.teacher.typeOfUser === "user") {
      axios
        .post("/api/users/getalltemp", data)
        .then((res) => {
          if (this._isMounted && res.data.notInRegion) {
            this.setState({
              tempJobs: res.data.jobs || [],
              inactiveJobs: res.data.inactivejobs || [],
              isLoading: false,
              noJobs: res?.data?.jobs?.length <= 0,
              resMsg: res.data.msg,
            });
          } else {
            let jobs = res.data.activejobs;
            //sorting jobs, newest added first
            jobs = jobs?.sort((a, b) => {
              let d = new Date(
                parseInt(a._id.toString().substring(0, 8), 16) * 1000
              );
              let d2 = new Date(
                parseInt(b._id.toString().substring(0, 8), 16) * 1000
              );
              return d2 - d;
            });

            //this._isMounted && jobs.push(this.getAdminMsg());

            this._isMounted &&
              this.setState({
                tempJobs: jobs,
                inactiveJobs: res.data.inactivejobs,
                isLoading: false,
                noJobs: false,
              });
          }
        })
        .catch((err) => console.log(err));
    } else {
      let dataOther = {
        email: this.props.auth.teacher.email,
        school: this.props.auth.teacher.school,
        type: this.props.auth.teacher.typeOfUser,
      };
      this._isMounted &&
        axios.post("/api/shared/get/jobs", dataOther).then((res) => {
          if (this._isMounted && res.data.notInRegion) {
            this.setState({
              tempJobs: res.data.jobs || [],
              inactiveJobs: [],
              isLoading: false,
              noJobs: res?.data?.jobs?.length <= 0,
              resMsg: res.data.msg,
            });
          } else {
            let jobs = res.data.activejobs;
            jobs = jobs?.sort((a, b) => {
              let d = new Date(
                parseInt(a._id.toString().substring(0, 8), 16) * 1000
              );
              let d2 = new Date(
                parseInt(b._id.toString().substring(0, 8), 16) * 1000
              );
              return d2 - d;
            });
            this._isMounted &&
              this.setState({
                tempJobs: jobs,
                inactiveJobs: [],
                isLoading: false,
                noJobs: false,
              });
          }
        });
    }
  };

  componentWillUnmount() {
    /* window.removeEventListener("resize", this.handler) */
    this._isMounted = false;
  }

  showSidebar = () => {
    const { sidebar } = this.state;
    if (sidebar) {
      this.setState({ sidebar: false });
    } else if (!sidebar) {
      this.setState({ sidebar: true });
    }
  };

  handleOpen = (e) => {
    const data = {
      uniqueId: e.target.id,
      email: this.props.auth.teacher.email,
    };

    this.props.incViewCount(data, this.props.history);

    for (let i = 0; i < this.state.tempJobs.length; i++) {
      if (this.state.tempJobs[i].uniqueId === e.target.id) {
        this.setState({ specificJob: this.state.tempJobs[i] });
      }
    }
    this.setState({ isOpen: true });
  };

  handleClose = ({ isOpen }) => {
    this.setState({ isOpen: false });
  };

  listOrBlock = (e) => {
    if (e.currentTarget.id === "block") {
      this.setState({ listSort: false });
    }
    if (e.currentTarget.id === "list") {
      this.setState({ listSort: true });
    }
  };

  handleSort = (e) => {
    switch (e.target.id) {
      case "datum":
        var sorted = this.state.tempJobs?.sort((a, b) =>
          a.startDate.localeCompare(b.startDate)
        );
        this.setState({ applicants: sorted });
        break;
      case "tid":
        var sorted = this.state.tempJobs?.sort((a, b) =>
          a.startTime.localeCompare(b.startTime)
        );
        this.setState({ applicants: sorted });
        break;
      default:
        break;
    }
  };

  openJob = (e) => {
    this.props.history.push(`/specific/${e.currentTarget.id}`);
  };

  /*  handleApply = e => {
         const data = {
             uniqueId: e.target.id,
             userEmail: this.props.auth.teacher.email,
             alreadyWarned: false
         }
         this.props.addApplicant(data, this.props.history)
     }

     confirmApply = e => {
         const data = {
             uniqueId: e.target.id,
             userEmail: this.props.auth.teacher.email,
             alreadyWarned: true
         }

         this.props.addApplicant(data, this.props.history)
     } */

  typeOfButton = (job) => {
    if (job.applicants.indexOf(this.props.auth.teacher.email) > -1) {
      return (
        <Button variant="custom" block id={job.uniqueId} disabled>
          Redan anmäld!
        </Button>
      );
    } else {
      return (
        <Button
          variant="custom"
          block
          id={job.uniqueId}
          onClick={this.handleOpen}
        >
          Visa
        </Button>
      );
    }
  };

  /* bellOrNot = (job) => {
        const { teacher } = this.props.auth;
        if (!job?.views?.includes(teacher.email) || typeof job?.views === "undefined") {
            return <Container className={this.state.listSort ? "status-container denied" : "mobile-container denied d-border"}>
                <FontAwesomeIcon size="lg" icon={faBell} />
            </Container>
        } else if (job.sos) {
            return <Container className={this.state.listSort ? "status-container denied" : "mobile-container denied d-border"}>
                <FontAwesomeIcon size="lg" icon={faExclamationCircle} />
            </Container>
        }
    } */
  mobileBell = (job) => {
    const { teacher } = this.props.auth;
    if (job.school === "Admin" && !job?.views?.includes(teacher.email)) {
      if (job.fromAdminType === "Update") {
        return (
          <div className="mobile-admin-container">
            <img alt="" src={BellImportant} className="mobile-bell" />
          </div>
        );
      } else {
        return (
          <div className="mobile-admin-tips-container">
            <img alt="" src={BellImportant} className="mobile-bell" />
          </div>
        );
      }
    }
    if (job.sos && !job?.views?.includes(teacher.email)) {
      return (
        <div className="mobile-sos-container">
          <img alt="" src={SOSIcon} className="mobile-bell" />
        </div>
      );
    }
    if (
      job?.views?.includes(teacher.email) ||
      typeof job?.views === "undefined"
    ) {
      return (
        <div className="mobile-seen-container">
          <img alt="" src={SeenIcon} className="mobile-bell-seen" />
        </div>
      );
    }
    if (
      !job?.views?.includes(teacher.email) ||
      typeof job?.views === "undefined"
    ) {
      return (
        <div className="mobile-bell-container">
          <img alt="" src={BellImportant} className="mobile-bell" />
        </div>
      );
    }
  };

  displayDates = (job) => {
    var options = { day: "numeric", month: "numeric" };
    if (
      new Date(job.startDate).toDateString() ===
      new Date(job.endDate).toDateString()
    ) {
      return new Date(job.startDate).toLocaleDateString("fi-FI");
    }
    return (
      new Date(job.startDate).toLocaleDateString("fi-FI", options) +
      "-" +
      new Date(job.endDate).toLocaleDateString("fi-FI")
    );
  };

  onLogoutClick = () => {
    this.props.logoutTeacher();
  };

  render() {
    const {
      tempJobs,
      isLoading,
      sidebar,
      listSort,
      noJobs,
      resMsg,
      mobile,
      inactiveJobs,
      language,
    } = this.state;
    const { teacher } = this.props.auth;
    const imgUrl = `${window.location.origin}/api/shared/image/`;
    return (
      <div className="temp-body">
        <CustomNavbar
          userType={teacher.typeOfUser}
          sidebar={sidebar}
          showSidebar={this.showSidebar}
          page={language.nav_available}
          user={teacher}
          logoutTeacher={this.onLogoutClick}
          isAuth={this.props.auth.isAuthenticated}
        />
        {!mobile && (
          <>
            <Sort
              header={language.userjobtitle}
              listOrBlock={this.listOrBlock}
              handleSort={this.handleSort}
              userType={teacher.typeOfUser}
              marginLeft={sidebar ? "215px" : "16px"}
            />
            <hr
              style={{ marginTop: "60px", position: "fixed", zIndex: "9" }}
              className="custom-hr"
            ></hr>
          </>
        )}
        <div className={sidebar ? "main push-right" : "main "}>
          <Container
            fluid
            style={{ paddingLeft: "0", paddingRight: "0", minHeight: "100%" }}
          >
            <ToastContainer
              position="bottom-center"
              hideProgressBar={false}
              closeOnClick
              draggable
            />
            {/* {mobile &&<span style={{position: '', marginLeft: "0"}}>
                        <MobileHeader text="Vikariat" icon={Vikariat} />
                        </span>
                    } */}
            {!isLoading ? (
              !mobile ? (
                !noJobs ? (
                  listSort ? (
                    tempJobs.map((job, index) => (
                      <LongCard
                        bell={this.mobileBell(job)}
                        index={index}
                        job={job}
                        openJob={this.openJob}
                        dates={this.displayDates(job)}
                        sos={job.sos}
                        language={language}
                      />
                    ))
                  ) : (
                    <Row>
                      {tempJobs.map((job, index) => (
                        <BlockCard
                          bell={this.mobileBell(job)}
                          index={index}
                          job={job}
                          openJob={this.openJob}
                          dates={this.displayDates(job)}
                          sos={job.sos}
                          language={language}
                        />
                      ))}
                    </Row>
                  )
                ) : (
                  <Row className="text-center mt-5 text-muted">
                    <Col className="d-flex">
                      <Card
                        style={{
                          border: "none",
                          backgroundColor: "#ffa53a",
                          color: "white",
                          fontSize: "1.6em",
                          fontWeight: "600",
                          textAlign: "center",
                          width: "50%",
                          borderRadius: "14px",
                          boxShadow: "0px 0px 24px 0px rgb(13 17 77 / 10%)",
                          margin: "auto",
                        }}
                      >
                        <Col>
                          <Card.Body>
                            <Card.Text>{resMsg}</Card.Text>
                          </Card.Body>
                        </Col>
                      </Card>
                    </Col>
                  </Row>
                )
              ) : (
                <MobileLayout
                  icon={Vikariat}
                  text={language.nav_tempjobs}
                  jobs={tempJobs}
                  inactivejobs={inactiveJobs}
                  mobileBell={this.mobileBell}
                  displayDates={this.displayDates}
                  openJob={this.openJob}
                  renderCard={true}
                  swipeable={true}
                  user={teacher}
                  inactivatejob={this.props.inactivateJob}
                  activatejob={this.props.reactivateJob}
                  removeJob={this.props.hideJobForUser}
                  blockSchool={this.props.blockSchool}
                  language={language}
                  noJobs={noJobs}
                  resMsg={resMsg}
                />
              )
            ) : (
              <div className="spin-wrapper">
                <Spinner size="8x" spinning={"spinning"} />
              </div>
            )}
          </Container>
        </div>
      </div>
    );
  }
}
UserShowTempJobs.propTypes = {
  addApplicant: PropTypes.func.isRequired,
  incViewCount: PropTypes.func.isRequired,
  logoutTeacher: PropTypes.func.isRequired,
  reactivateJob: PropTypes.func.isRequired,
  inactivateJob: PropTypes.func.isRequired,
  hideJobForUser: PropTypes.func.isRequired,
  blockSchool: PropTypes.func.isRequired,
  getAdminmessages: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  addApplicant,
  incViewCount,
  logoutTeacher,
  inactivateJob,
  reactivateJob,
  hideJobForUser,
  getAdminmessages,
  blockSchool,
})(withRouter(UserShowTempJobs));
