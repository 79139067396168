import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { registerPrincipal } from '../../actions/authActions';
import classnames from 'classnames';
import { Form, Button, Container } from 'react-bootstrap';
import '../../styles/body-verticalCenter.css';

class PrincipalReg extends Component{
    constructor(){
        super();
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            password: "",
            password2: "",
            school: "",
            errors: {}
        };
    };

    componentDidMount(){
        if(this.props.auth.isAuthenticated){
            this.props.history.push("/dashboard");
        }
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSubmit = e => {
        e.preventDefault();

        const newPrin = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            phone: this.state.phone,
            school: this.state.school,
            password: this.state.password,
            password2: this.state.password2
        }
        this.props.registerPrincipal(newPrin, this.props.history);
    }

    render(){
        const { errors } = this.state;
        return(
            <div className="body">
                <Link to="/">Hem</Link>
                <Container className="vertical-center">
                <h1>Rektor</h1>
                <Form noValidate onSubmit={this.onSubmit}>
                        <Form.Group controlId="firstName">
                            <Form.Label>Förnamn</Form.Label>
                            <Form.Control
                                type="text"
                                onChange={this.onChange}
                                value={this.state.firstName}
                                className={classnames("", {
                                    invalid: errors.firstName
                                })}
                            />
                            <Form.Text className="text-danger">{errors.firstName}</Form.Text>
                        </Form.Group>
                        <Form.Group controlId="lastName">
                            <Form.Label>Efternamn</Form.Label>
                            <Form.Control
                                type="text"
                                onChange={this.onChange}
                                value={this.state.lastName}
                                className={classnames("", {
                                    invalid: errors.lastName
                                })}
                            />
                            <Form.Text className="text-danger">{errors.lastName}</Form.Text>
                        </Form.Group>
                        <Form.Group controlId="school">
                            <Form.Label>Skola</Form.Label>
                            <Form.Control
                                type="text"
                                onChange={this.onChange}
                                value={this.state.school}
                                className={classnames("", {
                                    invalid: errors.school
                                })}
                            />
                            <Form.Text className="text-danger">{errors.school}</Form.Text>
                        </Form.Group>
                        <Form.Group controlId="phone">
                            <Form.Label>Tlfn.</Form.Label>
                            <Form.Control
                                type="text"
                                onChange={this.onChange}
                                value={this.state.phone}
                                className={classnames("", {
                                    invalid: errors.phone
                                })}
                            />
                            <Form.Text className="text-danger">{errors.phone}</Form.Text>
                        </Form.Group>
                        <Form.Group controlId="email">
                            <Form.Label>E-post</Form.Label>
                            <Form.Control
                                type="text"
                                onChange={this.onChange}
                                value={this.state.email}
                                className={classnames("", {
                                    invalid: errors.email
                                })}
                            />
                            <Form.Text className="text-danger">{errors.email}</Form.Text>
                        </Form.Group>
                        <Form.Group controlId="password">
                            <Form.Label>Lösenord</Form.Label>
                            <Form.Control
                                type="password"
                                onChange={this.onChange}
                                value={this.state.password}
                                className={classnames("", {
                                    invalid: errors.password
                                })}
                            />
                            <Form.Text className="text-danger">{errors.password}</Form.Text>
                        </Form.Group>
                        <Form.Group controlId="password2">
                            <Form.Label>Bekräfta lösenord</Form.Label>
                            <Form.Control
                                type="password"
                                onChange={this.onChange}
                                value={this.state.password2}
                                className={classnames("", {
                                    invalid: errors.password2
                                })}
                            />
                            <Form.Text className="text-danger">{errors.password2}</Form.Text>
                        </Form.Group>
                        <Button variant="custom" block size="lg" type="submit">Bli medlem</Button>
                    </Form>
                </Container>
            </div>
        );
    };
};

PrincipalReg.propTypes = {
    registerPrincipal: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    {registerPrincipal}
)(withRouter(PrincipalReg));