import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { checkSecurityCode } from "../../actions/authActions";
import classnames from "classnames";
import { Form, Button, Container, Row, Col, Image } from "react-bootstrap";
import logo from "../../images/logo.png";
import LanguagePicker from "../func-components/languagePicker";
import Cookies from "js-cookie";

class SecurityCode extends Component {
  constructor() {
    super();
    this.state = {
      code: "",
      errors: {},
      language: Cookies.getJSON("_lang") ? Cookies.getJSON("_lang") : "fi",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/profile");
    }
  }

  handleLanguage = (e) => {
    this.setState({ language: e.currentTarget.id }, () => {
      Cookies.set("_lang", this.state.language);
    });
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const codeToCompare = {
      code: this.state.code,
    };
    this.props.checkSecurityCode(codeToCompare, this.props.history);
  };

  render() {
    const { errors, language } = this.state;

    return (
      <Container className="vh-100 vw-100 d-flex flex-column align-items-center justify-content-center">
        <Image fluid src={logo} alt="Logo" />
        <Form
          noValidate
          onSubmit={this.onSubmit}
          className="w-100"
          style={{ maxWidth: "35em" }}
        >
          <Form.Group controlId="code" className="my-4">
            <Form.Control
              type="text"
              placeholder={language === "SV" ? "Skolans kod" : "Koulun koodi"}
              onChange={this.onChange}
              value={this.state.code}
              className={classnames("", {
                invalid: errors.code || errors.codenotfound,
              })}
            />
            <Form.Text className="text-danger">
              {errors.code}
              {errors.codenotfound}
            </Form.Text>
          </Form.Group>
          <Button variant="custom" block type="submit">
            {language === "fi"
              ? "Jatka rekisteröitymiseen"
              : "Fortsätt till registreringen"}
          </Button>
        </Form>
      </Container>
    );
  }
}

SecurityCode.propTypes = {
  checkSecurityCode: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { checkSecurityCode })(SecurityCode);
