import React, { useState, useEffect } from "react";
import { Dropdown, Button, Row, Col, Container } from "react-bootstrap";
import { BsGrid, BsListTask } from "react-icons/bs";
import { IconContext } from "react-icons";
import { useBreakpoints } from "../func-components/mediaHooks";
import { default as Max } from "../navigation/icons/maximize.svg";
import { default as Min } from "../navigation/icons/minimize.svg";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import sv from "../../texts/sv.json";
import fi from "../../texts/fi.json";
import {
  activateAllTeachers,
  deactivateAllTeachers,
} from "../../actions/authActions";
import { useAlert } from "react-alert";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";

const Sort = (props) => {
  const {
    header,
    listOrBlock,
    handleSort,
    userType,
    marginLeft,
    school,
    isJobs,
  } = props;

  const alert = useAlert();

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [language, setLanguage] = useState({});
  const [url, setUrl] = useState("");
  const [moreSchools, setMoreSchools] = useState(false);

  const changeSort = useBreakpoints().sortBreakpoint;

  useEffect(() => {
    let isMounted = true;
    if (props.auth.teacher.typeOfUser === "principal") {
      const data = {
        email: props.auth.teacher.email,
      };
      axios
        .post("/api/principals/get/principal", data)
        .then((res) => {
          if (res.data.moreSchools.length >= 1) {
            isMounted && setMoreSchools(true);
          }
        })
        .catch((err) => console.error(err));
    }
    if (props.auth.teacher.siteLang === 2) {
      isMounted && setLanguage(sv);
    } else if (props.auth.teacher.siteLang === 1) {
      isMounted && setLanguage(fi);
    } else {
      isMounted && setLanguage(fi);
    }
    isMounted &&
      setUrl(
        window.location.href.substring(
          window.location.href.lastIndexOf("/") + 1
        )
      );

    return () => {
      isMounted = false;
    };
  }, []);

  function Minimize() {
    setIsFullScreen(false);
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }

  function Maximize() {
    var elem = document.documentElement;
    setIsFullScreen(true);
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    }
  }

  return (
    <IconContext.Provider
      value={{ style: { fontSize: "20px", color: "#555aa1" } }}
    >
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ fontSize: "1.8em", fontWeight: "600" }}
      />
      <Container
        fluid
        style={{
          backgroundColor: "#ecedf3",
          position: "fixed",
          zIndex: "10",
          marginBottom: "50px",
          /* boxShadow: "0px 0px 24px 0px rgb(13 17 77 / 10%)" */
        }}
      >
        <Row className="align-items-center" style={{ height: "60px" }}>
          <Col md="auto">
            {userType === "user" ? (
              <h4
                style={{
                  fontWeight: "500",
                  fontSize: "2.4em",
                  color: "#2e2f31",
                  marginLeft: marginLeft,
                  marginBottom: "0",
                }}
              >
                {header}
              </h4>
            ) : (
              <h4
                style={{
                  fontWeight: "500",
                  fontSize: "2.4em",
                  color: "#2e2f31",
                  marginLeft: marginLeft,
                  marginBottom: "0",
                }}
              >
                {header}{" "}
                <span
                  style={{
                    fontWeight: "400",
                  }}
                >
                  {!moreSchools && " — " + school}
                </span>
              </h4>
            )}
          </Col>
          {isJobs && (
            <Col style={{ textAlign: "right" }}>
              <Dropdown>
                <Dropdown.Toggle
                  className="custom-dropdown"
                  id="dropdown-basic"
                >
                  {language.sort}
                </Dropdown.Toggle>
                {userType === "user" ? (
                  <Dropdown.Menu className="sort-dropdown">
                    <Dropdown.Item
                      className="dd-item"
                      id="datum"
                      as="button"
                      onClick={handleSort}
                    >
                      {language.date}
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="dd-item"
                      id="tid"
                      as="button"
                      onClick={handleSort}
                    >
                      {language.sorttime}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                ) : (
                  <Dropdown.Menu className="sort-dropdown">
                    {/* <Dropdown.Item className="dd-item" id="alfabetisk" as="button" onClick={handleSort}>{language.alphabetical}</Dropdown.Item>
                                        <Dropdown.Item className="dd-item" id="teacher" as="button" onClick={handleSort}>{language.nav_teachers}</Dropdown.Item> */}
                    <Dropdown.Item
                      className="dd-item"
                      id="datum"
                      as="button"
                      onClick={handleSort}
                    >
                      {language.latest}
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="dd-item"
                      id="reverse"
                      as="button"
                      onClick={handleSort}
                    >
                      {language.oldest}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                )}
              </Dropdown>
            </Col>
          )}
          {handleSort && !changeSort && !isJobs && (
            <Col style={{ textAlign: "right" }}>
              <Dropdown>
                <Dropdown.Toggle
                  className="custom-dropdown"
                  id="dropdown-basic"
                >
                  {language.sort}
                </Dropdown.Toggle>
                {userType === "user" ? (
                  <Dropdown.Menu className="sort-dropdown">
                    <Dropdown.Item
                      className="dd-item"
                      id="datum"
                      as="button"
                      onClick={handleSort}
                    >
                      {language.date}
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="dd-item"
                      id="tid"
                      as="button"
                      onClick={handleSort}
                    >
                      {language.sorttime}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                ) : (
                  <Dropdown.Menu className="sort-dropdown">
                    <Dropdown.Item
                      className="dd-item"
                      id="anmälning"
                      as="button"
                      onClick={handleSort}
                    >
                      {language.sortapplied}
                    </Dropdown.Item>
                    {/* NOTE: NOT WORKING, CANT FIGURE OUT HOW TO DO IT  */}
                    <Dropdown.Item
                      className="dd-item"
                      id="alfabetisk"
                      as="button"
                      onClick={handleSort}
                    >
                      {language.alphabetical}
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="dd-item"
                      id="teacher"
                      as="button"
                      onClick={handleSort}
                    >
                      {language.fi ? "Opettajat" : "Lärare"}
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="dd-item"
                      id="student"
                      as="button"
                      onClick={handleSort}
                    >
                      {language.fi ? "Opiskelijat" : "Studerande"}
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="dd-item"
                      id="other"
                      as="button"
                      onClick={handleSort}
                    >
                      {language.otherteacher}
                    </Dropdown.Item>
                    {/* <Dropdown.Item className="dd-item" id="datum" as="button" onClick={handleSort}>{language.date}</Dropdown.Item> */}
                    {/* <Dropdown.Item id="erfarenhet" as="button" onClick={handleSort}>Erfarenhet</Dropdown.Item> */}
                  </Dropdown.Menu>
                )}
              </Dropdown>
            </Col>
          )}

          <Col
            className="d-none d-md-block ml-auto"
            style={{ marginRight: "6rem", textAlign: "right" }}
            md="auto"
          >
            {handleSort && changeSort && !isJobs && (
              <Dropdown className="mt-1">
                <Dropdown.Toggle
                  className="custom-dropdown"
                  id="dropdown-basic"
                >
                  {language.sort}
                </Dropdown.Toggle>
                {userType === "user" ? (
                  <Dropdown.Menu className="sort-dropdown">
                    <Dropdown.Item
                      className="dd-item"
                      id="datum"
                      as="button"
                      onClick={handleSort}
                    >
                      {language.date}
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="dd-item"
                      id="tid"
                      as="button"
                      onClick={handleSort}
                    >
                      {language.sorttime}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                ) : (
                  <Dropdown.Menu className="sort-dropdown">
                    <Dropdown.Item
                      className="dd-item"
                      id="anmälning"
                      as="button"
                      onClick={handleSort}
                    >
                      {language.sortapplied}
                    </Dropdown.Item>
                    {/* NOTE: NOT WORKING, CANT FIGURE OUT HOW TO DO IT  */}
                    <Dropdown.Item
                      className="dd-item"
                      id="alfabetisk"
                      as="button"
                      onClick={handleSort}
                    >
                      {language.alphabetical}
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="dd-item"
                      id="teacher"
                      as="button"
                      onClick={handleSort}
                    >
                      {language.nav_teachers}
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="dd-item"
                      id="student"
                      as="button"
                      onClick={handleSort}
                    >
                      {language.student}
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="dd-item"
                      id="other"
                      as="button"
                      onClick={handleSort}
                    >
                      {language.otherteacher}
                    </Dropdown.Item>
                    {/* <Dropdown.Item className="dd-item" id="datum" as="button" onClick={handleSort}>{language.date}</Dropdown.Item> */}
                    {/* <Dropdown.Item id="erfarenhet" as="button" onClick={handleSort}>Erfarenhet</Dropdown.Item> */}
                  </Dropdown.Menu>
                )}
              </Dropdown>
            )}
            {listOrBlock && (
              <>
                <Button onClick={listOrBlock} id="list" variant="sort">
                  <BsListTask />
                </Button>
                <Button onClick={listOrBlock} id="block" variant="sort">
                  <BsGrid />
                </Button>
              </>
            )}
            <Button
              onClick={isFullScreen ? Minimize : Maximize}
              id="max_or_min"
              variant="sort"
            >
              {isFullScreen ? (
                <img alt="" src={Min} />
              ) : (
                <img alt="" src={Max} />
              )}
            </Button>
          </Col>
        </Row>
      </Container>
    </IconContext.Provider>
  );
};
Sort.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  activateAllTeachers: PropTypes.func.isRequired,
  deactivateAllTeachers: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(mapStateToProps, {
  activateAllTeachers,
  deactivateAllTeachers,
})(Sort);
