import React from 'react'

function extractTime(job){
    let timestamp = job._id.toString().substring(0, 8)
    let today = new Date()
    let posted = new Date(parseInt(timestamp, 16) * 1000)
    //difference since posted and right now in hours
    let diff = (today.getTime() - posted.getTime()) / 1000
    diff /= 60
    /* diff = diff / 60 */
    if (diff > 60) {
        diff = diff / 60

        if (diff > 24) {
            let days = Math.round(diff / 24)
            diff = Math.round(diff / (24 * days))
            return <p style={{fontSize: "1.4em"}}>(Tillagd för {days} {days === 1 ? "dag" : "dagar"} sedan)</p>
        }
        diff = Math.abs(Math.round(diff))
        return <p style={{fontSize: "1.4em"}}>(Tillagd för {diff} {diff === 1 ? "timme" : "timmar"} sedan)</p>
    }
    diff = Math.abs(Math.round(diff))
    return <p style={{fontSize: "1.4em"}}>(Tillagd för {diff} {diff === 1 ? "minut" : "minuter"} sedan)</p>
}

export{
    extractTime
}
