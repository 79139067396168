import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  logoutTeacher,
  addApplicant,
  removeApplicant,
  incViewCount,
  restoreJob,
} from "../../actions/authActions";
import Spinner from "../func-components/spinner";
import CustomNavbar from "../navigation/customNavbar";
import axios from "axios";
import {
  Card,
  Container,
  Row,
  Col,
  Button,
  ListGroup,
  Form,
} from "react-bootstrap";
import ChosenForJob from "../func-components/chosenForJob";
import "./viewJob.css";
import ApplyText from "../../texts/applyToJobText";
import ApplyModal from "./applyModal";
import Backbar from "../mobile/backbar";
import fi from "../../texts/fi.json";
import sv from "../../texts/sv.json";
import { withAlert } from "react-alert";
import { default as GotJobIcon } from "./icons/congrats_1.svg";

class ViewJob extends Component {
  constructor() {
    super();
    this.state = {
      sidebar: window.matchMedia("(max-width: 1450px)").matches ? false : true,
      job: {},
      school: {},
      isLoading: true,
      isOpen: false,
      fileDownloading: false,
      applyIsOpen: false,
      mobile: false,
      errors: {},
      language: {},
    };
  }

  componentDidMount() {
    const data = {
      id: this.props.match.params.jobid,
    };
    this.getLang(this.props.auth.teacher);
    const incData = {
      uniqueId: this.props.match.params.jobid,
      email: this.props.auth.teacher.email,
    };
    this.props.incViewCount(incData, this.props.history);

    const handler = () => {
      let match = window.matchMedia("(max-width: 600px)").matches;
      if (match) {
        this.setState({ mobile: true });
      } else {
        this.setState({ mobile: false })
      }
    };
    handler();
    window.addEventListener("resize", handler);

    axios
      .post("/api/users/get/job", data)
      .then((res) => {
        console.log(res.data);
        this.setState(
          { job: res.data.job, school: res.data.school, isLoading: false },
          () => console.log(this.state.job)
        );
      })
      .catch((err) => console.log(err));
  }

  /* static getDerivedStateFromProps(nextProps, state) {
        if (nextProps.errors) {
            return {
                errors: nextProps.errors
            }
        }
        return null
    } */

  getLang = (user) => {
    if (user.siteLang === 2) {
      this.setState({ language: sv });
    } else if (user.siteLang === 1) {
      this.setState({ language: fi });
    } else {
      this.setState({ language: fi });
    }
  };

  handleCloseChange = () => {
    this.setState({ isOpen: false });
  };

  handleApplyCloseChange = () => {
    this.setState({ applyIsOpen: false });
  };

  handleOpenChange = (e) => {
    this.setState({ isOpen: true });
  };

  handleApplyOpenChange = (e) => {
    this.setState({ applyIsOpen: true });
  };

  handleApply = (e) => {
    const data = {
      uniqueId: this.props.match.params.jobid,
      userEmail: this.props.auth.teacher.email,
      alreadywarned: false,
    };
    /* this.props.addApplicant(data, this.props.history) */
    axios
      .post("/api/users/addapplicant", data)
      .then((res) => {
        this.props.history.push("/jobs/personal");
      })
      .catch((err) => {
        if (err.response) {
          this.setState({ errors: err.response.data });
        }
        console.log(err);
      });
  };

  confirmApply = (e) => {
    const data = {
      uniqueId: this.props.match.params.jobid,
      userEmail: this.props.auth.teacher.email,
      alreadywarned: true,
    };

    axios
      .post("/api/users/addapplicant", data)
      .then((res) => {
        this.props.history.push("/jobs/personal");
      })
      .catch((err) => {
        if (err.response) {
          this.setState({ errors: err.response.data });
        }
        console.log(err);
      });
  };

  onLogoutClick = () => {
    this.props.logoutTeacher();
  };

  showSidebar = () => {
    const { sidebar } = this.state;
    if (sidebar) {
      this.setState({ sidebar: false });
    } else if (!sidebar) {
      this.setState({ sidebar: true });
    }
  };

  handleRemove = (e) => {
    const alert = this.props.alert;
    e.preventDefault();

    const data = {
      id: e.target.id,
      email: this.props.auth.teacher.email,
    };

    if (
      window.confirm(
        this.state.language.fi
          ? "Haluatko poistaa ilmoittautumisesi?"
          : "Vill du radera din anmälan?"
      )
    ) {
      this.props.removeApplicant(data, this.props.history);
    } else {
      alert.show(
        this.state.language.fi ? "Ilmoittautumisesi säilyy" : "Anmälan kvarstår"
      );
    }
  };

  resetJob = (e) => {
    e.preventDefault();

    const data = {
      id: e.target.id,
      email: this.props.auth.teacher.email,
    };

    this.props.restoreJob(data, this.props.history);
  };

  onDownloadClick = (e) => {
    e.preventDefault();
    axios
      .get(`/api/shared/file/${e.currentTarget.id}`, { responseType: "blob" })
      .then((res) => {
        console.log(res);
        this.setState({ fileDownloading: true }, () => {
          const file = new Blob([res.data], {
            type: res.data.type,
          });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        });
      })
      .then(() => {
        this.setState({ fileDownloading: false });
        console.log("done");
      })
      .catch((err) => console.log(err));
  };

  goBack = () => {
    this.props.history.goBack();
  };

  handleApplyModal = () => {
    this.setState({ openApplyModal: true });
  };

  displayDates = (job) => {
    var options = { day: "numeric", month: "numeric" };
    if (
      new Date(job.startDate).toDateString() ===
      new Date(job.endDate).toDateString()
    ) {
      return new Date(job.startDate).toLocaleDateString("fi-FI");
    }
    return (
      new Date(job.startDate).toLocaleDateString("fi-FI", options) +
      "-" +
      new Date(job.endDate).toLocaleDateString("fi-FI")
    );
  };

  buttonOrChosen = (job) => {
    const { language } = this.state;
    if (job.chosen === this.props.auth.teacher.email) {
      return "";
    } else if (
      typeof job.chosen === "undefined" &&
      job.applicants.includes(this.props.auth.teacher.email)
    ) {
      return (
        <Button
          variant="customDelete"
          block
          id={job.uniqueId}
          onClick={this.handleRemove}
        >
          {this.state.language.removeapl}
        </Button>
      );
    } else if (
      job.chosen !== this.props.auth.teacher.email &&
      typeof job.chosen !== "undefined"
    ) {
      return "";
    } else {
      return (
        <Button
          variant="custom"
          block
          onClick={this.handleApplyOpenChange}
          id={job.uniqueId}
        >
          {this.state.language.apply}
        </Button>
      );
    }
  };

  render() {
    const { teacher } = this.props.auth;
    const { job, sidebar, isLoading, school, mobile, errors, language } =
      this.state;
    return (
      <div className="show-body">
        <CustomNavbar
          userType={teacher.typeOfUser}
          sidebar={sidebar}
          showSidebar={this.showSidebar}
          page={language.nav_available}
          user={teacher}
          logoutTeacher={this.onLogoutClick}
          isAuth={this.props.auth.isAuthenticated}
        />
        <div className={sidebar ? "viewjob-main push-right" : "viewjob-main"}>
          {!isLoading ? (
            <Container
              fluid
              style={{ minHeight: "100vh", marginBottom: "80px" }}
            >
              {/* {window.innerWidth < 600 &&
                                <FontAwesomeIcon size="lg" className="go-back-icon" onClick={this.goBack} icon={faArrowAltCircleLeft} />
                            } */}
              <Card
                className="mt-3 mb-3"
                style={{
                  borderRadius: "10px",
                  border: "none",
                  boxShadow: "0px 0px 24px 0px rgba(13, 17, 77, 0.10)",
                }}
              >
                <Form>
                  <Backbar
                    title={language.back}
                    padding={true}
                    goback={this.goBack}
                    sticky={mobile}
                    desktop={!mobile}
                  />
                  {job.school !== "Admin" ? (
                    <>
                      <Row className="f-col">
                        <Col>
                          <Card.Body className="my-0 py-0">
                            <Row>
                              <Col>
                                {job.chosen ===
                                  this.props.auth.teacher.email && (
                                    <Container
                                      className="got-job-container"
                                      fluid
                                    >
                                      <img
                                        src={GotJobIcon}
                                        className="got-job-icon"
                                        alt=""
                                      />
                                      <p className="got-job-text">
                                        {language.gotjoblong}{" "}
                                        {this.props.auth.teacher.firstName}!
                                      </p>
                                      <p className="got-job-text-2">
                                        {language.gotjoblong2}
                                      </p>
                                    </Container>
                                  )}
                                {job.chosen !== this.props.auth.teacher.email &&
                                  typeof job.chosen !== "undefined" && (
                                    <Container
                                      className="other-got-job-container"
                                      fluid
                                    >
                                      <p className="got-job-text">
                                        {language.didnotgetjob}
                                      </p>
                                      <p className="got-job-text-2">
                                        {language.didnotgetjob2}{" "}
                                        {this.props.auth.teacher.firstName}!
                                      </p>
                                    </Container>
                                  )}
                              </Col>
                            </Row>
                          </Card.Body>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="f-col">
                          <Card.Body>
                            <Card.Title className="cBold t-lg tc-dark">
                              <h5>{language.jobinfo}</h5>
                            </Card.Title>
                            <Row className="cBold t-md f-col">
                              <Form.Group controlId="name" as={Col}>
                                <Form.Label className="text-muted cBold">
                                  {language.school}
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  className="custom-control"
                                  placeholder={school.name}
                                />
                              </Form.Group>
                              <Form.Group controlId="address" as={Col}>
                                <Form.Label className="text-muted cBold">
                                  {language.address}
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  className="custom-control"
                                  placeholder={school.address}
                                />
                              </Form.Group>
                            </Row>
                            <Row className="cBold t-md f-col">
                              <Form.Group controlId="dates" as={Col}>
                                <Form.Label className="text-muted cBold">
                                  {language.date}
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  className="custom-control"
                                  placeholder={this.displayDates(job)}
                                />
                              </Form.Group>
                              <Form.Group controlId="time" as={Col}>
                                <Form.Label className="text-muted cBold">
                                  {language.times}
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  className="custom-control"
                                  placeholder={`${job.startTime} - ${job.endTime}`}
                                />
                              </Form.Group>
                            </Row>
                            <Row className="cBold t-md f-col">
                              <Form.Group controlId="type" as={Col}>
                                <Form.Label className="text-muted cBold">
                                  {language.jobfortemp}
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  className="custom-control"
                                  placeholder={
                                    language.fi
                                      ? job?.typeOfTemp?.name_fi
                                      : job?.typeOfTemp?.name_swe
                                  }
                                />
                              </Form.Group>
                            </Row>
                            <Row className="cBold t-md f-col">
                              <Form.Group controlId="subjects" as={Col}>
                                <Form.Label className="text-muted cBold">
                                  {language.grade}
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  className="custom-control"
                                  placeholder={job.grades.join(", ")}
                                />
                              </Form.Group>
                            </Row>
                            <Row className="cBold t-md f-col">
                              <Form.Group controlId="subjects" as={Col}>
                                <Form.Label className="text-muted cBold">
                                  {language.subjects}
                                </Form.Label>
                                <Form.Control
                                  as="textarea"
                                  disabled
                                  className="custom-control"
                                  placeholder={job.subjects.join(", ")}
                                />
                              </Form.Group>
                            </Row>
                            <Row className="cBold t-md f-col">
                              <Form.Group controlId="extra" as={Col}>
                                <Form.Label className="text-muted cBold">
                                  {language.extra}
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  as="textarea"
                                  rows={4}
                                  className="custom-control"
                                  placeholder={job.additionalInfo}
                                />
                              </Form.Group>
                            </Row>
                            <Row className="cBold t-md f-col">
                              <Col>
                                {job.chosen ===
                                  this.props.auth.teacher.email ? (
                                  <ListGroup>
                                    {job.files.map((file, index) => (
                                      <ListGroup.Item
                                        id={file}
                                        onClick={this.onDownloadClick}
                                      >
                                        {file.substr(32)}
                                      </ListGroup.Item>
                                    ))}
                                  </ListGroup>
                                ) : (
                                  ""
                                )}
                              </Col>
                            </Row>
                            {job.chosen === this.props.auth.teacher.email && (
                              <Row className="cBold t-md f-col">
                                <Form.Group controlId="number" as={Col}>
                                  <Form.Label className="text-muted cBold">
                                    {language.phone}
                                  </Form.Label>
                                  <Form.Control
                                    disabled
                                    className="custom-control"
                                    placeholder={school.phone}
                                  />
                                </Form.Group>
                              </Row>
                            )}
                          </Card.Body>

                          {/*  <Card.Body>
                                                <Card.Title className="cBold t-lg tc-dark">
                                                    <h5>Information om skolan</h5>
                                                </Card.Title>
                                                <Row className="cBold t-md f-col">
                                                    <Form.Group controlId="name" as={Col}>
                                                        <Form.Label className="text-muted cBold">Skola</Form.Label>
                                                        <Form.Control disabled className="custom-control" placeholder={school.name} />
                                                    </Form.Group>
                                                    <Form.Group controlId="address" as={Col}>
                                                        <Form.Label className="text-muted cBold">Address</Form.Label>
                                                        <Form.Control disabled className="custom-control" placeholder={school.address} />
                                                    </Form.Group>
                                                </Row>
                                            </Card.Body> */}
                        </Col>
                      </Row>
                      <Row className="f-col">
                        <Col>
                          <Card.Body>
                            <Row>
                              <Col>{this.buttonOrChosen(job)}</Col>
                            </Row>
                            {
                              /* console.log(job.hidefrom) */
                              job.hidefrom.includes(
                                this.props.auth.teacher.email
                              ) && (
                                <Row className="mt-4">
                                  <Col>
                                    <Button
                                      variant="customDelete"
                                      id={job.uniqueId}
                                      onClick={this.resetJob}
                                      size="lg"
                                      block
                                    >
                                      Återställ slängt vikariat
                                    </Button>
                                  </Col>
                                </Row>
                              )
                            }
                          </Card.Body>
                        </Col>
                      </Row>
                    </>
                  ) : job.fromAdminType === "Update" ? (
                    <Row>
                      <Col className="f-col">
                        <Card.Body>
                          <Card.Title className="cBold t-lg tc-dark">
                            <h5>Update</h5>
                          </Card.Title>

                          <Row className="cBold t-md f-col">
                            <Form.Group controlId="extra" as={Col}>
                              <Form.Label className="text-muted cBold">
                                Information about update
                              </Form.Label>
                              <Form.Control
                                disabled
                                as="textarea"
                                rows={10}
                                className="custom-control"
                                placeholder={job.additionalInfo}
                              />
                            </Form.Group>
                          </Row>
                        </Card.Body>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col className="f-col">
                        <Card.Body>
                          <Card.Title className="cBold t-lg tc-dark">
                            <h5>Vikaaria tiedottaa/informerar</h5>
                          </Card.Title>

                          <Row className="cBold t-md f-col">
                            <Form.Group controlId="extra" as={Col}>
                              <Form.Label className="text-muted cBold">
                              </Form.Label>
                              <Form.Control
                                disabled
                                as="textarea"
                                rows={10}
                                className="custom-control"
                                placeholder={job.additionalInfo}
                              />
                            </Form.Group>
                          </Row>
                        </Card.Body>
                      </Col>
                    </Row>
                  )}
                </Form>
              </Card>
            </Container>
          ) : (
            <div className="spin-wrapper">
              <Spinner size="8x" spinning={"spinning"} />
            </div>
          )}
          <ChosenForJob
            isOpen={this.state.isOpen}
            handleCloseChange={this.handleCloseChange}
            tempJob={job}
          />
          <ApplyModal
            isOpen={this.state.applyIsOpen}
            handleCloseChange={this.handleApplyCloseChange}
            text={errors.msg ? errors.msg : ApplyText}
            errormsg={errors.msg ? true : false}
            job={job}
            handleApply={this.handleApply}
            language={language}
            confirmApply={this.confirmApply}
          />
        </div>
      </div>
    );
  }
}

ViewJob.propTypes = {
  logoutTeacher: PropTypes.func.isRequired,
  addApplicant: PropTypes.func.isRequired,
  incViewCount: PropTypes.func.isRequired,
  removeApplicant: PropTypes.func.isRequired,
  restoreJob: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  logoutTeacher,
  addApplicant,
  removeApplicant,
  incViewCount,
  restoreJob,
})(withRouter(withAlert()(ViewJob)));
