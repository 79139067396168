import { 
    SET_CURRENT_TEACHER, 
    TEACHER_LOADING, 
    SET_USED_CODE,
    VALID_RESET_TOKEN,
    IS_EDITING,
    SUCCESS
} from '../actions/types';

const isEmpty = require('is-empty');

const initialState = {
    isAuthenticated: false,
    teacher: {},
    loading: false,
    usedCode: false,
    validResetToken: false,
    isEditing: false
};

export default function(state = initialState, action){
    switch(action.type){
        case SET_CURRENT_TEACHER:
            return{
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                teacher: action.payload
            };
        case TEACHER_LOADING:
            return{
                ...state,
                loading: true
            };
        case SET_USED_CODE:
            return{
                ...state,
                usedCode: true
            };
        case VALID_RESET_TOKEN:
            return{
                ...state,
                validResetToken: true
            };
        case IS_EDITING:
            return{
                ...state,
                isEditing: true
            };
        default:
            return state;
    }
}
