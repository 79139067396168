import React from "react";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const ContactApplicant = (props) => {
  const { isOpen, handleCloseChange, language, applicant } = props;

  const callApplicant = () => {};
  return language.sv ? (
    <Modal
      show={isOpen}
      shouldCloseOnOverlayClick={false}
      onHide={handleCloseChange}
      dialogClassName="apply-consent-modal"
      centered
      animation={false}
    >
      <Modal.Header>
        <Modal.Title style={{ fontSize: "2em" }}>
          Fint, {props.auth.teacher.firstName}! Du har vikarie.
        </Modal.Title>
        {/* <button type="button" class="close" onClick={handleCloseChange} style={{ opacity: "1" }}>
                    <span aria-hidden="true"><img src={CloseIcon} alt="" className="errorApplyCloseBtn" style={{ width: "1.3em" }} /></span>
                    <span class="sr-only">Close</span>
                </button> */}
      </Modal.Header>
      <Modal.Body>
        <p style={{ fontSize: "1.6em", fontWeight: "500" }}>
          Vänligen kontakta vikarien snarast möjligt.
        </p>
        <Button href={`tel:${applicant.phone}`} variant="sortActivate" block>
          Ring
        </Button>
        <Button href={`mailto:${applicant.email}`} variant="sortActivate" block>
          Skicka e-post
        </Button>
      </Modal.Body>
      <Modal.Footer className="px-0 py-4">
        <Button variant="custom" block onClick={handleCloseChange}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  ) : (
    <Modal
      show={isOpen}
      shouldCloseOnOverlayClick={false}
      onHide={handleCloseChange}
      dialogClassName="apply-consent-modal"
      centered
      animation={false}
    >
      <Modal.Header>
        <Modal.Title style={{ fontSize: "2em" }}>
          Hienoa {props.auth.teacher.firstName}! Sinulla on sijainen.
        </Modal.Title>
        {/*  <button type="button" class="close" onClick={handleCloseChange} style={{ opacity: "1" }}>
                    <span aria-hidden="true"><img src={CloseIcon} alt="" className="errorApplyCloseBtn" style={{ width: "1.3em" }} /></span>
                    <span class="sr-only">Close</span>
                </button> */}
      </Modal.Header>
      <Modal.Body>
        <p style={{ fontSize: "1.6em", fontWeight: "500" }}>
          Olethan piakkoin yhteydessä sijaiseen.
        </p>
        <Button href={`tel:${applicant.phone}`} variant="sortActivate" block>
          Soita
        </Button>
        <Button href={`mailto:${applicant.email}`} variant="sortActivate" block>
          Lähetä sähköpostia
        </Button>
      </Modal.Body>
      <Modal.Footer className="px-0 py-4">
        <Button variant="custom" block onClick={handleCloseChange}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
ContactApplicant.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(mapStateToProps, {})(ContactApplicant);
