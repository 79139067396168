import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createNewCode } from '../../actions/authActions';
import Navbar from '../navigation/navbar';
import axios from 'axios';

//bootstrap && css imports
import { Form, Col, Button, Row, Container } from 'react-bootstrap';
import '../../styles/body-verticalCenter.css';


class CreateCode extends Component {
    constructor() {
        super();
        this.state = {
            code: "",
            codes: [],
            usedCodes: [],
            isLoading: true,
            errors: {}
        }
    }

    componentDidMount(){
        if(this.props.auth.teacher.typeOfUser !== "principal"){
            this.props.history.push("/profile")
        }

        const data = {
            email: this.props.auth.teacher.email
        }

        axios.post("/api/principals/get/codes", data)
            .then(res => {
                this.setState({codes: res.data, isLoading: false}, () => {
                    let arr = [];
                    for(let i = 0; i < this.state.codes.length; i++){                      
                        if(this.state.codes[i].used === true){
                            arr.push(this.state.codes[i])                       
                            this.state.codes.splice(i, 1)
                        }
                    }
                    this.setState({usedCodes: arr})
                })
            }).catch(err => console.log(err.response))
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSubmit = e => {
        e.preventDefault();

        const data = {
            code: this.state.code,
            email: this.props.auth.teacher.email
        }

        this.props.createNewCode(data);
    }

    render() {
        const { isLoading } = this.state.isLoading;
        const { codes, usedCodes } = this.state;
        return (
            <div className="body">
                <Navbar />
                <Container className="vertical-center">
                    <Row className="justify-content-sm-center">
                        <Form noValidate onSubmit={this.onSubmit}>
                            <Form.Group controlId="code">
                                <Form.Label>Ny Kod</Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={this.onChange}
                                    value={this.state.code}
                                />
                            </Form.Group>
                            <Button variant="custom" block type="submit">
                                Skapa ny kod
                        </Button>
                        </Form>
                    </Row>
                    <Row className="justify-content-sm-center text-center mt-5"> 
                        <Col xs={6}>
                            Oanvända koder
                        </Col>
                        <Col xs={6}>
                            Använda koder
                        </Col>
                    </Row>
                    <Row className="justify-content-sm-center text-center">
                        <Col xs={6}>
                            {!isLoading ? (
                                codes.map(code => 
                                    <p>
                                        {code.code}
                                    </p> 
                                )
                            ): <h1>Loading...</h1>}
                        </Col>
                        <Col xs={6}>
                            {!isLoading ? (
                                usedCodes.map(usedcode => 
                                    <p>
                                        {usedcode.code}
                                    </p>
                                )
                            ): <h1>Loading...</h1>}
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }

}

CreateCode.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    createNewCode: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
})

export default connect(
    mapStateToProps,
    { createNewCode }
)(withRouter(CreateCode))
