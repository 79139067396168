import React, {useState} from "react"
import {Button, Form, Modal} from "react-bootstrap";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {sendNewConfirmationEmail} from "../../actions/authActions";
import isEmail from "validator/es/lib/isEmail";

const NewAccountModal = (props) => {
    const {onHide, show, setSentAgain} = props;
    const [newEmailState, setNewEmailState] = useState({
        email: "",
        showEmailField: false,
        noEmail: false,
    })
    const sendNewConfirmationEmail = () => {

        if(!newEmailState.email || newEmailState.email === "" || !isEmail(newEmailState.email)){
            setNewEmailState({...newEmailState, noEmail: true})
            return;
        }
        const data = {
            email: newEmailState.email,
        };
        props.sendNewConfirmationEmail(data)
        setSentAgain(true)
        onHide()
    }

    return(<>
        <Modal style={{fontSize: "1.4em"}} show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Aktivoi tili.</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Jotta voit alkaa käyttämän sovellusta, sinun täytyy aktivoida tili (antamasi sähköpostiosoite).</p>
                <Form.Group>
                <Form.Control
                    size="lg"
                    id="email"
                    type="email"
                    placeholder="Sähköposti"
                    value={newEmailState.email}
                    onChange={(e) => setNewEmailState({...newEmailState, email: e.currentTarget.value, noEmail: false})}
                    autoCapitalize="none"
                    className={newEmailState.noEmail ? "border-danger" : ""}
                />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Olen jo aktivoinut tilin.
                </Button>
                <Button variant="custom" onClick={sendNewConfirmationEmail}>
                    Lähetä uusi aktivointilinkki.
                </Button>
            </Modal.Footer>
        </Modal>

    </>)
}
NewAccountModal.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.string.isRequired,
    sendNewConfirmationEmail: PropTypes.func.isRequired
};
const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});
export default connect(mapStateToProps, {sendNewConfirmationEmail})(NewAccountModal);
